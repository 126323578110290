import React, { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import CardReservation from '../cards/card-reservation';
import NumberFormat from "react-number-format";
import { Consumer } from '../../context';

class Reservation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hotel: ''
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        let cart = [], zonas = [], hoteles = [];
        if (this.props.cart) {
            cart = this.props.cart;
            console.log("Carrito res: ", cart);
        }

        if (this.props.zonas) {
            zonas = this.props.zonas;
        }

        if (this.props.hoteles) {
            hoteles = this.props.hoteles;
        }

        let tc = 1;
        const exrate = window.localStorage.getItem('exrate');
        if(exrate === 'MXN') {
            tc = this.props.context.tc;
        } else if(exrate === 'USD') {
            tc = 1;
        }

        return (
            <div className="reservation justify-center">
                <div className="container">
                    <div className="reservation-sections row-responsive">
                        <div className="reservation-left column">
                            <div className="reservation-title justify-center">
                                <h3><FormattedMessage defaultMessage="DATOS DE CONTACTO" id="carrito_datos_contacto" /></h3>
                            </div>
                            <div className="white-space-24"></div>
                            <div className="all-traveler-data column">
                                <div className="traveler-data column">
                                    <p><FormattedMessage defaultMessage="Nombre" id="carrito_nombre" /></p>
                                    <div className="white-space-8"></div>
                                    <input className="input" type="text" name="name" id="name" required/>
                                </div>
                                <div className="traveler-data column">
                                    <p><FormattedMessage defaultMessage="Apellidos" id="carrito_apellidos" /></p>
                                    <div className="white-space-8"></div>
                                    <input className="input" type="text" name="last_name" id="last_name" required/>
                                </div>
                                <div className="traveler-data column">
                                    <p><FormattedMessage defaultMessage="Correo" id="footer_correo" /></p>
                                    <div className="white-space-8"></div>
                                    <input className="input" type="email" name="email" id="email" required/>
                                </div>
                                <div className="traveler-data column">
                                    <p><FormattedMessage defaultMessage="País" id="carrito_pais" /></p>
                                    <div className="white-space-8"></div>
                                    <select className="input" name="country" id="country">
                                        <option value="México">México</option>
                                        <option value="Brasil">Brasil</option>
                                        <option value="Canadá">Canadá</option>
                                    </select>
                                </div>
                                <div className="traveler-data column">
                                    <p>Hotel</p>
                                    <div className="white-space-8"></div>
                                    <select className="input" name="hotel" id="hotel" onChange={(event) => { this.setState({ hotel: event.target.value }) }} required>
                                        <FormattedMessage id="reservacion_hotel" defaultMessage="Selecciona un hotel...">
                                            {(message) => <option value="">{message}</option>}
                                        </FormattedMessage>
                                        {zonas.map((item, index) =>
                                            <optgroup label={item.zona} key={index}>
                                                {hoteles.filter(hote => hote.zona === item.id_zona).map((hotel,i) => 
                                                    <option value={hotel.id_hotel} key={i}>{hotel.nombre}</option>
                                                )}
                                            </optgroup>
                                        )}
                                        <FormattedMessage id="reservacion_hotel_otro" defaultMessage="Otro">
                                            {(message) => <optgroup label={message}>
                                                <option value="otro">{message}</option>
                                            </optgroup>}
                                        </FormattedMessage>
                                    </select>
                                </div>
                                {this.state.hotel === "otro" ? 
                                    <div className="traveler-data column">
                                        <p><FormattedMessage defaultMessage="Otro" id="reservacion_hotel_otro" /></p>
                                        <div className="white-space-8"></div>
                                        <input className="input" type="text" name="other" id="other" required />
                                    </div> : null}
                                <div className="traveler-data column">
                                    <p><FormattedMessage defaultMessage="Teléfono" id="carrito_telefono" /></p>
                                    <div className="white-space-8"></div>
                                    <input className="input" type="text" name="telephone" id="telephone" required/>
                                </div>
                                <div className="traveler-data column">
                                    <p>
                                        <FormattedMessage defaultMessage="Comentario Adicional" id="carrito_comentario" />
                                    </p>
                                    <div className="white-space-8"></div>
                                    <textarea className="input" name="comment" id="comment"></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="reservation-right justify-center">
                            <div className="reservation-detail column">
                                <div className="reservation-detail-title justify-center">
                                    <h3><FormattedMessage defaultMessage="DETALLES DE RESERVACIÓN" id="carrito_resumen" /></h3>
                                </div>
                                <div className="reservation-detail-cart column">
                                    {cart.map((item, index) =>
                                        <CardReservation key={index} item={item} /> )}
                                </div>
                                <div className="white-space-24"></div>
                                <div className="reservation-detail-total">
                                    <div className="row">
                                        <div className="total-title justify-end align-center">
                                            <h4>TOTAL: &nbsp;</h4>
                                        </div>
                                        <div className="total-price justify-center align-center">
                                            <p className="exrate">{exrate}</p>
                                            <h3>
                                                <NumberFormat
                                                    value={((cart.reduce((sum, product) => sum + product.total, 0)) * tc).toFixed(2)}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                    prefix={"$"}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true} 
                                                />
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                {this.props.context.user.auth && cart.length > 1 && <div className="justify-end column">
                                    <div className="white-space-8"></div>
                                    <p className="text-right color-red">Se aplicó un descuento adicional del {cart.length > 2 ? '10%' : cart.length > 1 ? '5%' : null}</p>
                                </div>}
                                {/*<div className="white-space-8"></div>
                                <div className="row justify-end">
                                    <p className="color-red"><FormattedMessage defaultMessage="Precios en" id="precios_en" />&nbsp; {exrate}</p>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Consumer(injectIntl(Reservation));