import React, { Component } from "react";
import {Consumer} from '../../context';
import { FormattedMessage, injectIntl } from 'react-intl';
import NumberFormat from "react-number-format";

class CardGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locale: this.props.locale
        }
    }
    
    render() {
        let { titulo, precio_adulto, descripcion_corta, imagen, descuento, agencia, descuento_adulto, descuento_menor } = this.props;
        console.log(this.props);
        if (titulo) {
            titulo = JSON.parse(titulo);
            titulo = titulo[this.props.locale];
        } else {
            titulo = '';
        }
        if (descripcion_corta) {
            descripcion_corta = JSON.parse(descripcion_corta);
            descripcion_corta = descripcion_corta[this.props.locale];
        } else {
            descripcion_corta = '';
        }

        //Calculo de descuento
        if (descuento > 0) {
            descuento = descuento / 100;
        }

        let tc = 1;
        const exrate = window.localStorage.getItem('exrate');
        if(exrate === 'MXN') {
            tc = this.props.context.tc;
        } else if(exrate === 'USD') {
            tc = 1;
        }

        return (
            <div className="card-grid column">
                <div className="grid-image justify-center responsive-img">
                    <img src={imagen} alt="Imagen" />
                    <div className="price justify-center align-center">
                        <p className="exrate">{exrate}</p>
                        {!agencia && descuento !== null && descuento > 0 ?
                        <p className="font-small line-through color-l-yellow">
                            <NumberFormat
                                value={(precio_adulto * tc).toFixed(2)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                                decimalScale={2}
                                fixedDecimalScale={true}
                            />
                        </p> : null} &nbsp;
                        <span>
                            <NumberFormat
                                value={!agencia ? (descuento_adulto * tc).toFixed(2) : (precio_adulto * tc).toFixed(2)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                                decimalScale={2}
                                fixedDecimalScale={true}
                            />
                        </span>{/*<strike><span className="desc">$800</span></strike>*/}
                    </div>
                </div>
                <div className="grid-info column">
                    <div className="first-text">
                        <h3>{titulo}</h3>
                    </div>
                    <div className="second-text">
                        <div className="row">
                            <ul className="list-inline">
                                <li className="place">{descripcion_corta}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/*{descuento !== null && descuento > 0 ?
                <div className="ribbon-wrapper-red">
                    <div className="ribbon-red"><FormattedMessage defaultMessage="OFERTA" id="producto_oferta" /></div>
                </div> : null}*/}
            </div>
        )
    }
}

export default Consumer(injectIntl(CardGrid));