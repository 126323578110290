/**
 *  index.js
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Manejador principal del estado global de la aplicación
 */

import React, { Component, createContext } from "react";
import { saveReservation, addtoCart, removeFromCart, saveIdChat, cleanCart, getTC } from './methods';
import { Login, Logout } from './users';

// Instancia del Context, métodos: Provider y Consumer

const Context = createContext();

class GlobalContext extends Component {
	state = {
		cart: [],
		saveReservation: saveReservation.bind(this),
		addtoCart: addtoCart.bind(this),
		removeFromCart: removeFromCart.bind(this),
		saveIdChat: saveIdChat.bind(this),
		cleanCart: cleanCart.bind(this),
		getTC: getTC.bind(this),
		login: Login.bind(this),
		logout: Logout.bind(this),
		user: []
	};

	async componentDidMount() {
		let user = window.localStorage.getItem('tousr');
		if (user) {
			//console.log(user);
			user = JSON.parse(user);
			this.setState({ user: user });
		} else {
			this.setState({ user: {auth: false} });
		}
	}
	
	render() {
    	return (
      		<Context.Provider value={this.state}>
        		{ this.props.children }
      		</Context.Provider>
    	);
  	}
}

/**
 * @function: Consumer
 * @description: HOC conector entre el estado global y un componente consumidor.
 * @param: Component => Componente Web
 *
 */

const Consumer = Component => {
	return props => {
    	return (
      		<Context.Consumer>
        		{ context => <Component {...props} context={context} /> }
      		</Context.Consumer>
    	);
  	};
};

export { Consumer, GlobalContext };