/**
 *  index.js
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Punto de Entrada de React
 * 	@process: 1
*/

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'react-app-polyfill/ie11';
import WebFont from 'webfontloader';

// Hoja de estilos

import './sass/style.scss';

// Método para cargar la fuente de Google de manera asíncrona

WebFont.load({
    google: {
        families: ['Roboto:400,500,700, 900', 'sans-serif']
    }
});

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.register();
