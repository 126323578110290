import React, { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import Imagen from '../../img/nado-xenotes-03.jpg';
import NumberFormat from "react-number-format";
import { Consumer } from "../../context";
import cardReservation from "./card-reservation";
import moment from 'moment-timezone';

class CardCart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subadults: 0,
            subchildren: 0,
            sub: 0
        }
    }

    render() {
        let locale = window.localStorage.getItem('cto_locale');
        if (!locale) {
            locale = 'es';
        }

        let cart = [], titulo = '', index = 0;
        if (this.props.position) {
            index = this.props.position;
        }

        if (this.props.cart) {
            cart = this.props.cart;   
            if (this.props.cart.product.titulo) {
                titulo = JSON.parse(this.props.cart.product.titulo);
                titulo = titulo[locale];
            }
        }

        let tc = 1;
        const exrate = window.localStorage.getItem('exrate');
        if(exrate === 'MXN') {
            tc = this.props.context.tc;
        } else if(exrate === 'USD') {
            tc = 1;
        }

        return (
            <div className="card-cart-gral column">
                <div className="row">
                    <div className="column cardcart-img justify-center">
                        <div className="responsive-img justify-center">
                            <img className="cover-img " src={cart.product.imagen} alt=""/>
                        </div>                
                    </div>
                    <div className="column data-tour justify-center">
                        <div className="info-tour container">
                            <div className="column">
                                <div className="row">
                                    <div className="label align-center">
                                        <h3 className="weight-semi font-small">Tour:</h3>
                                    </div>
                                    <div className="data">
                                        <h3 className="weight-mbold font-regular color-red">{titulo}</h3>
                                    </div>      
                                </div>
                                <div className="white-space-8"></div>
                                <div className="row">
                                    <div className="label align-center">
                                        <h3 className="weight-semi font-small">
                                            <FormattedMessage defaultMessage="Fecha: " id="producto_fecha" />
                                        </h3>
                                    </div>
                                    <div className="data">
                                        <h3 className="weight-bold font-small">{moment(cart.fecha).format('DD/MM/YYYY')}</h3>
                                    </div>      
                                </div>
                                <div className="white-space-8"></div>
                                <div className="row">
                                    <div className="label align-center">
                                        <h3 className="weight-semi font-small">
                                            <FormattedMessage defaultMessage="Precio adulto: " id="carrito_precio_adulto" />
                                        </h3>
                                    </div>
                                    <div className="data">
                                        <h3 className="weight-bold font-small">${(cart.product.precio_adulto * tc).toFixed(2)}</h3>
                                    </div>      
                                </div>
                                <div className="white-space-8"></div>
                                <div className="row">
                                    <div className="label align-center">
                                        <h3 className="weight-semi font-small">
                                            <FormattedMessage defaultMessage="Adultos: " id="producto_adultos" />
                                        </h3>
                                    </div>
                                    <div>
                                        <input className="input input-cart"
                                            type="number"
                                            name="adults"
                                            min="1"
                                            defaultValue={cart.adultos}
                                            onChange={(event) => {
                                                const sub = event.target.value;
                                                cart.adultos = sub;
                                                let carrito = window.localStorage.getItem('cart');
                                                if (carrito) {
                                                    carrito = JSON.parse(carrito);
                                                }
                                                Object.assign(carrito[index], { adultos: sub, total: (sub * cart.product.precio_adulto) + (cart.menores * cart.product.precio_menor) });
                                                this.props.updateCart(carrito);
                                                carrito = JSON.stringify(carrito);
                                                window.localStorage.setItem('cart', carrito);
                                            }}
                                            required />
                                    </div> 
                                </div>
                                <div className="white-space-8"></div>
                                <div className="row">
                                    <div className="label align-center">
                                        <h3 className="weight-semi font-small">
                                            <FormattedMessage defaultMessage="Precio niño: " id="carrito_precio_niño" />
                                        </h3>
                                    </div>
                                    <div className="data">
                                        <h3 className="weight-bold font-small">${(cart.product.precio_menor * tc).toFixed(2)}</h3>
                                    </div>
                                </div>
                                <div className="white-space-8"></div>
                                <div className="row">
                                    <div className="label align-center">
                                        <h3 className="weight-semi font-small">
                                            <FormattedMessage defaultMessage="Niños: " id="producto_niños" />
                                        </h3>
                                    </div>
                                    <div>
                                        <input className="input input-cart"
                                            type="number"
                                            name="children"
                                            min="0"
                                            defaultValue={cart.menores}
                                            onChange={(event) => {
                                                const sub = event.target.value;
                                                cart.menores = sub;
                                                let carrito = window.localStorage.getItem('cart');
                                                if (carrito) {
                                                    carrito = JSON.parse(carrito);
                                                }
                                                Object.assign(carrito[index], { menores: sub, total: (sub * cart.product.precio_menor) + (cart.adultos * cart.product.precio_adulto) });
                                                this.props.updateCart(carrito);
                                                carrito = JSON.stringify(carrito);
                                                window.localStorage.setItem('cart', carrito);
                                            }}
                                            required />
                                    </div>    
                                </div>
                                <div className="white-space-8"></div>
                                {/*<div className="row">
                                    <div className="label align-center">
                                        <h3 className="weight-semi font-small">
                                            <FormattedMessage defaultMessage="Descuento: " id="carrito_descuento" />
                                        </h3>
                                    </div>
                                    <div className="data">
                                        <h3 className="weight-bold font-small">-20%</h3>
                                    </div>      
                                        </div>*/}
                            </div>
                        </div>
                    </div>
                    <div className="column price align-center justify-center">
                        <h1 className="color-green">
                            <NumberFormat
                                value={(((cart.adultos * cart.product.precio_adulto) + (cart.menores * cart.product.precio_menor)) * tc).toFixed(2)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                                decimalScale={2}
                                fixedDecimalScale={true}
                            />    
                        </h1>
                    </div>
                    <div className="column auto">
                        <a onClick={this.props.removeFCart.bind(this, cart, this.props.position)}><i className="fas fa-trash font-regular color-gray delete-icon"></i></a>
                    </div>
                </div>
                <div className="white-space-16"></div>
            </div>
        )
    }
}

export default Consumer(injectIntl(CardCart));