import React, { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import NumberFormat from "react-number-format";
import { Consumer } from "../../context";
import moment from 'moment-timezone';

class CardReservation extends Component {
    render() {
        let product = [], titulo = '';

        let locale = window.localStorage.getItem('cto_locale');
        if (!locale) {
            locale = 'es';
        }

        console.log("Item: ", this.props.item)

        if (this.props.item) {
            product = this.props.item;
            titulo = JSON.parse(this.props.item.product.titulo);
            titulo = titulo[locale];
        }

        let descuento = 0;
        if (this.props.item.product.descuento) {
            descuento = this.props.item.product.descuento;
            //Calculo de descuento
            if (descuento > 0) {
                descuento = descuento / 100;
            }
        }

        let tc = 1;
        const exrate = window.localStorage.getItem('exrate');
        if(exrate === 'MXN') {
            tc = this.props.context.tc;
        } else if(exrate === 'USD') {
            tc = 1;
        }

        return (
            <div className="card-reservation row-responsive">
                <div className="column data-tour justify-center align-center">
                    <div className="info-tour container">
                        <div className="column">
                            <div className="row">
                                <div className="label align-center">
                                    <h3 className="weight-semi font-small">Tour:</h3>
                                </div>
                                <div className="data">
                                    <h3 className="weight-mbold font-regular color-red">{titulo}</h3>
                                </div>
                            </div>
                            <div className="white-space-8"></div>
                            <div className="row">
                                <div className="label align-center">
                                    <h3 className="weight-semi font-small">
                                        <FormattedMessage defaultMessage="Fecha: " id="producto_fecha" />
                                    </h3>
                                </div>
                                <div className="data">
                                    <h3 className="weight-bold font-small">{moment(product.fecha).format('DD/MM/YYYY')}</h3>
                                </div>
                            </div>
                            <div className="white-space-8"></div>
                            <div className="row">
                                <div className="label align-center">
                                    <h3 className="weight-semi font-small">
                                        <FormattedMessage defaultMessage="Precio adulto: " id="carrito_precio_adulto" />
                                    </h3>
                                </div>
                                <div className="data align-center">
                                    <h3 className="weight-bold font-small">
                                        <NumberFormat
                                            value={!product.agencia ? (product.product.descuento_adulto * tc) : (product.product.precio_adulto * tc)}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                            decimalScale={2}
                                            fixedDecimalScale={true} />
                                    </h3>
                                </div>
                            </div>
                            <div className="white-space-8"></div>
                            <div className="row">
                                <div className="label align-center">
                                    <h3 className="weight-semi font-small">
                                        <FormattedMessage defaultMessage="Adultos: " id="producto_adultos" />
                                    </h3>
                                </div>
                                <div className="data">
                                    <h3 className="weight-bold font-small">{product.adultos}</h3>
                                </div>
                            </div>
                            <div className="white-space-8"></div>
                            <div className="row">
                                <div className="label align-center">
                                    <h3 className="weight-semi font-small">
                                        <FormattedMessage defaultMessage="Precio niño: " id="carrito_precio_niño" />
                                    </h3>
                                </div>
                                <div className="data align-center">
                                    <h3 className="weight-bold font-small">
                                        <NumberFormat
                                            value={!product.agencia ? (product.product.descuento_menor * tc) : (product.product.precio_menor * tc)}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                            decimalScale={2}
                                            fixedDecimalScale={true} />
                                    </h3>
                                </div>
                            </div>
                            <div className="white-space-8"></div>
                            <div className="row">
                                <div className="label align-center">
                                    <h3 className="weight-semi font-small">
                                        <FormattedMessage defaultMessage="Niños: " id="producto_niños" />
                                    </h3>
                                </div>
                                <div className="data">
                                    <h3 className="weight-bold font-small">{product.menores}</h3>
                                </div>
                            </div>
                            {/*<div className="white-space-8"></div>
                            <div className="row">
                                <div className="label align-center">
                                    <h3 className="weight-semi font-small">
                                        <FormattedMessage defaultMessage="Descuento: " id="carrito_descuento" />
                                    </h3>
                                </div>
                                <div className="data">
                                    <h3 className="weight-bold font-small">-20%</h3>
                                </div>
                            </div>*/}
                        </div>
                    </div>
                </div>
                <div className="column price justify-center align-center">
                <div className="row align-center justify-center">
                    <h3 className="color-green">
                        <NumberFormat
                        value={(product.total * tc).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        decimalScale={2}
                        fixedDecimalScale={true} />
                    </h3>
                </div>
                </div>
            </div>
        )
    }
}

export default Consumer(injectIntl(CardReservation));