import React, { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import Request from '../core/httpClient';
import NumberFormat from "react-number-format";
import { Consumer } from '../context';
import moment from 'moment-timezone';
import datepicker from 'js-datepicker';
import 'js-datepicker/dist/datepicker.min.css';

const req = new Request();

class ProductDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            principalImg: '',
            images: [],
            product: [],
            subadults: 0,
            subchildren: 0,
			fecha: null, //moment().format()
			options: [],
            id_opcion: 0,
            open: true
        }
    }

	componentDidMount() {
		this.load();
		this.loadVariaciones();
    }

    async load() {
        const id_producto = this.props.match.params.id;
        let data = '';

        let user = window.localStorage.getItem("tousr");

        if (user) {
            console.log(user);
            user = JSON.parse(user);

            const resUser = await req.post('/usuarios/tokuser', { id: user.id });
            console.log(resUser.data);
            if (resUser) {
                if (resUser.data) {
                    data = { id: id_producto, id_agencia: resUser.data.agencia };
                } else {
                    data = { id: id_producto, id_agencia: 0 };
                }
            }
        } else {
            data = { id: id_producto, id_agencia: 0 };
        }

        const res = await req.get('/products/product', data);
        console.log(res);
        if (res.product) {
            this.setState({ product: res.product, principalImg: res.product.imagen, activo: true });
            this.setDate(res);

            const images = this.state.images;
            for (let index = 0; index <= 4; index++) {
                const storage = window.firebase.storage().ref();
                if (storage) {
                    const ref = storage.child(`productos/${id_producto}/${index}.jpg`);
                    ref.getDownloadURL()
                        .then(url => {
                            images[index] = url;
                            this.setState({ images, principalImg: images[0] });
                        })
                }
            }
        } else {
            this.setState({ activo: false });
        }
    }

	async loadVariaciones() {
		const idProduct = this.props.match.params.id;
		let opcion = "0";
		const res = await req.get('/products/get/options/active', {idProduct});
        if (res.options) {
			this.setState({ options: res.options, id_opcion: res.options[0].id_opcion });
			opcion =  res.options[0].id_opcion;
        } else {
			this.setState({ options: [] });
		}
		//Obtener precios
		this.getPrices(this.state.fecha, opcion);
	}

	setDate(result) {
		// Fecha
        // console.log(new Date(), moment().toDate());
        // console.log(res.product.dias_reserva);
        let arrayFB = [], arrayFechas = [], fechas = [];
        if (result.product.fechas_bloqueadas) {
            arrayFB = JSON.parse(result.product.fechas_bloqueadas);
            if (arrayFB.fechas) {
                arrayFechas = arrayFB.fechas.split(',');

                arrayFechas.map((fech) => {
                    const arrayDate = fech.split('-');
                    fechas.push(new Date(parseInt(arrayDate[0]), parseInt(arrayDate[1] - 1), parseInt(arrayDate[2])))
                });
            }
        }

        const inputDate = document.getElementById('tour-date');
        if (inputDate) {
            datepicker(inputDate, {
                startDay: moment().add(result.product.dias_reserva ? result.product.dias_reserva : 5, 'days').toDate(),
                minDate: moment().add(result.product.dias_reserva ? result.product.dias_reserva : 5, 'days').toDate(),
                customMonths: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                customDays: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
                formatter: (input, date, instance) => {
					this.setState({ fecha: moment(date).format('') });
				},
				onSelect: (event, date) => {
					this.getPrices(date, this.state.id_opcion);
                },
                disabledDates: fechas,
                disabler: result.dayMonth !== '' ? eval('dat => ' + result.dayMonth) : null
            })
		}
	}

	async getPrices(date, id_opcion) {
		this.setState({loadingPrices: true});
		const id_producto = this.props.match.params.id;
		const res = await req.post('/products/get/prices', {id: id_producto, id_opcion, fecha: moment(date).format('YYYY-MM-DD')});
		// console.log(res);
		if(res.precios) {
			const product = this.state.product;
			product.precio_adulto = Math.ceil(res.precios.precio_adulto);
			product.precio_menor = Math.ceil(res.precios.precio_menor);
			product.descuento = res.precios.descuento;
			// console.log(product);
			this.setState({product});
		} else if(res.error) {
			alert(res.message);
		} else {
			this.load();
		}
		this.setState({loadingPrices: false});
	}
	
    changeImage(img, num) {
        const images = this.state.images;
        console.log(images);
        console.log(img, num);
        if (num === 1) {
            images[1] = this.state.principalImg;
        } 

        if (num === 2) {
            images[2] = this.state.principalImg;
        } 

        if (num === 3) {
            images[3] = this.state.principalImg;
        } 

        if (num === 4) {
            images[4] = this.state.principalImg;
        } 
        this.setState({ images, principalImg: img });
    }

    saveReservationData(event) {
        event.preventDefault();

        let niños = 0;
        const fecha = this.state.fecha;
        let adultos = event.target.adults.value;
        if (event.target.children.value !== null) {
            niños = event.target.children.value;
        } else {
            niños = 0;
        }

        let descuento = 0;
        descuento = this.state.product.descuento / 100;

		//Personas Tours Privados
		let paxDisabled = false;
		if(Array.isArray(this.state.options) && this.state.product.categoria == 31) {
			const find = this.state.options.find(opt => opt.id_opcion == this.state.id_opcion);
			if (find) {
				console.log(this.state.product.categoria);
				if (find.opcion.personas >= 1) {
					paxDisabled =  true;
					const adults = document.getElementById('adults');
					if (adults) {
						adults.value = find.opcion.personas;
					}
				}
			}
		}
		adultos = paxDisabled ? 1 : adultos;
		niños = paxDisabled ? 0 : niños;
        const total = (!this.state.product.agencia ? (adultos * this.state.product.descuento_adulto) + (niños * this.state.product.descuento_menor) : (adultos * this.state.product.precio_adulto) + (niños * this.state.product.precio_menor));
		const opcion = Array.isArray(this.state.options) ? this.state.options.find(opt => opt.id_opcion = this.state.id_opcion) : {}
        const product = { product: this.state.product, fecha: fecha, adultos: adultos, menores: niños, total: total, id_opcion: this.state.id_opcion, opcion: opcion.opcion, agencia: this.state.product.agencia };
        // console.log(this.state.product);
		if(this.state.product.precio_adulto && this.state.product.precio_menor && this.state.product.precio_adulto > 0) {
        	this.props.context.addtoCart(product); 
		   	this.props.history.push('/carrito');
		} else {
			alert('Prices cannot be null or 0');
		}
    }

    addToCart(product) {
		if(!this.state.loadingPrices) {
			this.props.context.addtoCart(product);
			this.props.history.push('/carrito');
		}
    }

    render() {
        let { titulo, precio_adulto, precio_menor, descuento, descripcion, incluye, noincluye, recomendaciones, link, pdf, edad_adultos, edad_menores, agencia, descuento_adulto, descuento_menor } = this.state.product;
        console.log(this.state.product);

		//Cálculo de descuento
		if(descuento > 0) {
			descuento = descuento / 100;
		}
        let locale = window.localStorage.getItem('cto_locale');
        if (!locale) {
            locale = 'es';
        }

        if (titulo) {
            titulo = JSON.parse(titulo);
            titulo = titulo[locale];
        } else {
            titulo = '';
        }

        if (descripcion) {
            descripcion = JSON.parse(descripcion);
            descripcion = descripcion[locale];
        } else {
            descripcion = '';
        }

        let arrayIncluye = [];
        if (incluye) {
            incluye = JSON.parse(incluye);
            incluye = incluye[locale];
            if (incluye !== null && incluye !== undefined && incluye !== '') {
                arrayIncluye = incluye.replace(/(\r\n|\n|\r)/gm, "").split(";");   
            }
        } else {
            incluye = '';
        }

        let arrayNoIncluye = [];
        if (noincluye) {
            noincluye = JSON.parse(noincluye);
            noincluye = noincluye[locale];
            if (noincluye !== null && noincluye !== undefined && noincluye !== '') {
                arrayNoIncluye = noincluye.replace(/(\r\n|\n|\r)/gm, "").split(";");
                //arrayNoIncluye = noincluye.replace(/\s+/, "").replace(/(\r\n|\n|\r)/gm, "").split(";");
            }
        } else {
            noincluye = '';
        }

        let arrayRecomendaciones = [];
        if (recomendaciones) {
            recomendaciones = JSON.parse(recomendaciones);
            recomendaciones = recomendaciones[locale];
            if (recomendaciones !== null && recomendaciones !== undefined && recomendaciones !== '') {
                arrayRecomendaciones = recomendaciones.replace(/(\r\n|\n|\r)/gm, "").split(";");
            }
            // console.log(recomendaciones);
        } else {
            recomendaciones = '';
        }

        if (pdf) {
            pdf = JSON.parse(pdf);
            pdf = pdf[locale];
        } else {
            pdf = '';
        }

        let tc = 1;
        const exrate = window.localStorage.getItem('exrate');
        if(exrate === 'MXN') {
            tc = this.props.context.tc;
        } else if(exrate === 'USD') {
            tc = 1;
        }

		//Personas Tours Privados
		let paxDisabled = false;
		if(Array.isArray(this.state.options) && this.state.product.categoria == 31) {
			const find = this.state.options.find(opt => opt.id_opcion == this.state.id_opcion);
			if (find) {
				console.log(this.state.product.categoria);
				if (find.opcion.personas >= 1) {
					paxDisabled =  true;
					const adults = document.getElementById('adults')
					if (adults) {
						adults.value = find.opcion.personas;
					}
				}
			}
		}
	
        return (
            <div className="product-detail column">
                <Header />
                {/*<TopOfPage data={data} />*/}
                <div className="white-space-48"></div>
                {this.state.activo ? <div className="product-detail-description justify-center align-center"> 
                    <div className="container row-responsive">
                        <div className="section-left column">
                            <div className="description-title row align-center">
                                <div className="auto">
                                    <h2 className="color-green">{titulo}</h2>
                                </div>
                            </div>
                            <div className="white-space-8"></div>
                            <div className="card-tour column">
                                <div className="principal-img justify-center">
                                    <img src={this.state.principalImg} alt=""/>
                                </div>
                                <div className="white-space-8"></div>
                                <div className="gallery row">
                                    {this.state.images.slice(1,5).map((image, index) => 
                                        <div key={index} className="gallery-img responsive-img bordered" onClick={this.changeImage.bind(this, image, (index + 1))}>
                                            <img className="gi" src={image} alt="Imagen producto"/>
                                            <div className="rd-parallax-gallery">
                                                <div className="top-text justify-center align-center">
                                                    <i className="fas fa-search-plus"></i>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="description column">
                                    <div className="description-title align-center">
                                        <div className="auto">
                                            <h3 className="color-blue"><FormattedMessage defaultMessage="Descripción" id="producto_descripcion" /></h3>
                                        </div>
                                        {pdf &&
                                        <React.Fragment>
                                            <div className="pdf align-center justify-end">
                                                <a className="font-tiny color-o-red" target="_blank" href={pdf}>
                                                    <i className="far fa-file-pdf"></i> Ver PDF de tour
                                                </a>
                                            </div>
                                        </React.Fragment>}
                                    </div>
                                    <div className="white-space-8"></div>
                                    <div className="description-text">
                                        <p className="text-justify">
                                            {descripcion}    
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="info-card column">
                            {arrayIncluye.length > 0 || arrayNoIncluye.length > 0 ?
                            <React.Fragment>
                            <div className="white-space-16"></div>
                            <div className="include-or-not row-responsive">
                                {arrayIncluye.length > 0 ?
                                <div className="include column">
                                    <div className="include-title">
                                        <h4 className="color-green"><FormattedMessage defaultMessage="¿Qué incluye el precio?" id="producto_incluye" /></h4>
                                    </div>
                                    <div className="white-space-16"></div>
                                    <div className="include-text">
                                        <ul>
                                            {arrayIncluye.map((item, index) =>
                                                <li key={index}>{item}</li>
                                            )}
                                        </ul>
                                    </div>
                                </div> : null}
                            </div>
                            </React.Fragment> : null}
                            <div className="white-space-48"></div>
                            <div className="tab">
                                <button className={`tablinks ${this.state.open ? 'active' : ''}`} onClick={(e) => this.setState({open: true})}>
									<FormattedMessage defaultMessage="Recomendaciones" id="producto_recomendaciones"/>
								</button>
                                <button className={`tablinks ${!this.state.open ? 'active' : ''}`} onClick={(e) => this.setState({open: false})}>
									<FormattedMessage defaultMessage="Información importante" id="informacion_importante"/>
								</button>
                            </div> 
                            <div className="box-tab">
                            {this.state.open ? 
                                <div className="recomendations column">
                                    <div className="recomendations-text">
                                        {/*<p className="text-justify">
                                            {recomendaciones}
                                        </p>*/}
                                        <ul className="include-text">
                                            {arrayRecomendaciones.map((item, index) =>
                                                <li key={index}>{item}</li>
                                            )}
                                        </ul>
                                    </div>
                                </div> :
                                <div className="no-include column">
                                    <div className="include-text">
                                        <ul>
                                            {arrayNoIncluye.map((item, index) =>
                                                <li key={index}>{item}</li>
                                            )}
                                        </ul>
                                    </div>
                                </div>}
                            </div>
                            <div className="white-space-24"></div>
                            {link ? 
                            <React.Fragment>
                                <div className="white-space-24"></div>
                                <div className="link column">
                                    <div className="link-title">
                                        <h4 className="color-green">Video</h4>
                                    </div>
                                    <div className="white-space-8"></div>
                                    <div className="link-text">
                                        <a className="truncate-ellipsis" href={link}>{link}</a>
                                    </div>
									<div>
									{/*<iframe width="560" height="315" src="https://www.youtube.com/embed/qc9Oh8z8I2c" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>*/}
									</div>
                                </div>
                            </React.Fragment> : null}
                            </div>
                        </div>
                        <div className="section-right column">
                            {/*<div className="info column">
                                <div className="price">
                                    <p><strong><FormattedMessage defaultMessage="Adultos: " id="producto_adultos" /></strong>{/*<strike>$900.00</strike>   ${(precio_adulto * tc).toFixed(2)}</p>
                                </div>
                                <div className="price">
                                    <p><strong><FormattedMessage defaultMessage="Niños: " id="producto_niños" /></strong>{/*<strike>$300.00</strike>   ${(precio_menor * tc).toFixed(2)}</p>
                                </div>
                                <div className="save">
                                    <p><strong><FormattedMessage defaultMessage="Ahorra: 50%!" id="producto_ahorro" /></strong></p>
                                </div>
                                <div className="duration">
                                    <p><strong><FormattedMessage defaultMessage="Duración: " id="producto_duracion" /></strong>8 horas (aproximadamente)</p>
                                </div>
                            </div>*/}
                            <div className="white-space-8"></div>
                            <div className="reservation column">
                                <div className="reservation-title justify-center align-center">
                                    <h4><FormattedMessage defaultMessage="Reservación" id="producto_reservacion" /></h4>
                                </div>
                                <form onSubmit={this.saveReservationData.bind(this)}>
                                    <div className="reservation-data column">
                                        {this.state.options.length > 0 && this.state.fecha || this.state.options.length === 0 ?
                                        <React.Fragment>
                                            <div className="reservation-prices row">
                                                <div className="column">
                                                    <div className="sub-adults justify-center">
                                                        <p className="weight-bold"><FormattedMessage defaultMessage="Precio adulto" id="producto_precio_adulto" /></p>
                                                    </div>
                                                    <div className="white-space-8"></div>
                                                    <div className="sub-children align-center">
                                                        {this.state.loadingPrices ?
                                                            <div className="column justify-center align-center">
                                                                <i className="fas fa-spinner fa-spin"></i>
                                                            </div> :
                                                            <div className="column align-center justify-center">
                                                                {!agencia && <p className="line-through color-darkgray">
                                                                    <NumberFormat
                                                                        value={descuento > 0 && `${(precio_adulto * tc).toFixed(2)} ${exrate}`}
                                                                        displayType={"text"}
                                                                        thousandSeparator={true}
                                                                        prefix={"$"}
                                                                        decimalScale={2}
                                                                        fixedDecimalScale={true} />
                                                                </p>}
                                                                <h3>
                                                                    <NumberFormat
                                                                        value={!agencia ? (descuento_adulto * tc).toFixed(2) : (precio_adulto * tc).toFixed(2)}
                                                                        displayType={"text"}
                                                                        thousandSeparator={true}
                                                                        prefix={"$"}
                                                                        decimalScale={2}
                                                                        fixedDecimalScale={true} />{exrate}
                                                                </h3>
                                                            </div>}
                                                    </div>
                                                </div>
                                                <div className="column">
                                                    <div className="sub-adults justify-center">
                                                        <p className="weight-bold"><FormattedMessage defaultMessage="Precio niño" id="producto_precio_niño" /></p>
                                                    </div>
                                                    <div className="white-space-8"></div>
                                                    <div className="sub-children justify-center">
                                                        {this.state.loadingPrices ?
                                                            <div className="column justify-center align-center">
                                                                <i className="fas fa-spinner fa-spin"></i>
                                                            </div> :
                                                            <div className="column align-center justify-center prices">
                                                                {!agencia && <p className="line-through color-darkgray">
                                                                    <NumberFormat
                                                                        value={descuento > 0 && `${(precio_menor * tc).toFixed(2)} ${exrate}`}
                                                                        displayType={"text"}
                                                                        thousandSeparator={true}
                                                                        prefix={"$"}
                                                                        decimalScale={2}
                                                                        fixedDecimalScale={true} />
                                                                </p>}
                                                                <h3>
                                                                    <NumberFormat
                                                                        value={!agencia ? (descuento_menor * tc).toFixed(2) : (precio_menor  * tc).toFixed(2)}
                                                                        displayType={"text"}
                                                                        thousandSeparator={true}
                                                                        prefix={"$"}
                                                                        decimalScale={2}
                                                                        fixedDecimalScale={true} />
                                                                    {exrate}
                                                                </h3>
                                                            </div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="white-space-16"></div>
                                        </React.Fragment> : null}
                                        <div className="reservation-date column">
                                            <div><p><strong><FormattedMessage defaultMessage="Fecha: " id="producto_fecha" /></strong></p></div>
                                            <div className="white-space-8"></div>
                                            <div>
                                                <input 
                                                    type="text"
                                                    className="input"
                                                    id="tour-date"
                                                    name="date"
                                                    required
                                                    value={this.state.fecha ? moment(this.state.fecha).format('DD/MM/YYYY') : ''}
                                                    placeholder="DD/MM/YYYY"
                                                    pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])/(0[1-9]|1[012])/[0-9]{4}"
                                                    title="DD/MM/YYYY"
                                                    autoComplete="off"/>
                                            </div>
                                        </div>
                                        <div className="white-space-8"></div>
                                        {this.state.options.length > 0 &&
                                            <div className="column">
                                                <div><p><strong><FormattedMessage defaultMessage="Variaciones" id="variaciones" /></strong></p></div>
                                                <div className="white-space-8"></div>
                                                <select className="input input-text" value={this.state.id_opcion} onChange={(event) => { this.setState({ id_opcion: event.target.value }); this.getPrices(this.state.fecha, event.target.value) }}>
                                                    {this.state.options.map(option => {
                                                        return <option key={option.id_opcion} value={option.id_opcion}>{option.opcion[this.props.intl.locale || 'es']}</option>
                                                    })}
                                                </select>
                                            </div>
                                        }
                                        <div className="white-space-8"></div>
                                        <div className="row-responsive">
                                            <div className="adults column">
                                                <div><p>
														<strong>
															<FormattedMessage defaultMessage="Adultos " id="producto_adultos" />{edad_adultos ? '('+edad_adultos+'):' : '(12+):'}
														</strong>
													</p>
												</div>
                                                <div className="white-space-8"></div>
                                                <div>
													<input className="input input-left"
														id="adults"
														type="number" 
														name="adults" 
														min="1" 
														defaultValue="1" 
														onChange={(event) => {
															if (event.target.value < 0 || parseFloat(event.target.value) > 99) {
																alert("Cantidad debe ser mayor a 0 y menor a 99");
																return;
															} else {
																const sub = event.target.value;
																const pa = (descuento_adulto).toFixed(2);
																this.setState({ subadults: sub });
															}
														}}
                                                   		required
														disabled={paxDisabled}/>
                                                </div>
                                            </div>
                                            <div className="children column">
                                                <div>
													<p>
														<strong>
															<FormattedMessage defaultMessage="Niños " id="producto_niños" />{edad_menores ? '('+edad_menores+'):' : '(5-11):'}
														</strong>
													</p>
												</div>
                                                <div className="white-space-8"></div>
                                                <div>
													<input className="input"
														id="children"
														type="number" 
														name="children" 
														min="0" 
														defaultValue="0"
														onChange={(event) => {
															if (event.target.value < 0 || parseFloat(event.target.value) > 99) {
																alert("Cantidad debe ser mayor a 0 y menor a 99");
																return;
															} else {
																const sub = event.target.value;
																const pm = (descuento_menor).toFixed(2);
																this.setState({ subchildren: sub });
															}
														}} 
														required
														disabled={paxDisabled}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="white-space-16"></div>
                                        {this.state.fecha ?
                                        <div className="subtotal column">
                                            <div className="row">
                                                <div className="sub-adults justify-end">
                                                    <p><FormattedMessage defaultMessage="Adultos" id="producto_adultos_sub" /></p>
                                                </div>
                                                <div className="sub-children justify-end">
                                                    {this.state.loadingPrices ?
                                                        <div className="column justify-center align-center">
                                                            <i className="fas fa-spinner fa-spin"></i>
                                                        </div> :
                                                        <h4>
                                                            {!agencia ? <NumberFormat
                                                                value={this.state.subadults === 0 ? (descuento_adulto * tc).toFixed(2) : (this.state.subadults * (descuento_adulto * tc)).toFixed(2)}
                                                                displayType={"text"}
                                                                thousandSeparator={true}
                                                                prefix={"$"}
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                            /> :
                                                            <NumberFormat
                                                                value={this.state.subadults === 0 ? (precio_adulto * tc).toFixed(2) : (this.state.subadults * (precio_adulto * tc)).toFixed(2)}
                                                                displayType={"text"}
                                                                thousandSeparator={true}
                                                                prefix={"$"}
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                            />
                                                            }
                                                        </h4> }
                                                </div>
                                            </div>
                                            <div className="white-space-8"></div>
                                            <div>
                                                <div className="sub-adults justify-end">
                                                    <p><FormattedMessage defaultMessage="Niños" id="producto_niños_sub" /></p>
                                                </div>
                                                <div className="sub-children justify-end">
                                                    {this.state.loadingPrices ?
                                                        <div className="column justify-center align-center">
                                                            <i className="fas fa-spinner fa-spin"></i>
                                                        </div> :
                                                        <h4>
                                                            {!agencia ? <NumberFormat
                                                                value={this.state.subchildren === 0 ? '0' : (this.state.subchildren * (descuento_menor * tc)).toFixed(2)}
                                                                displayType={"text"}
                                                                thousandSeparator={true}
                                                                prefix={"$"}
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                            /> :
                                                            <NumberFormat
                                                                value={this.state.subchildren === 0 ? '0' : (this.state.subchildren * (precio_menor * tc)).toFixed(2)}
                                                                displayType={"text"}
                                                                thousandSeparator={true}
                                                                prefix={"$"}
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                            />}
                                                        </h4> }
                                                </div>
                                            </div>
                                            <div className="white-space-16"></div>
                                            <div>
                                                <div className="sub-adults justify-end">
                                                    <h3>Total:</h3>
                                                </div>
                                                <div className="sub-children justify-end">
                                                    {this.state.loadingPrices ?
                                                        <div className="column justify-center align-center">
                                                            <i className="fas fa-spinner fa-spin"></i>
                                                        </div> :
                                                        <h3>
                                                            {!agencia ? <NumberFormat
                                                                value={this.state.subadults === 0 && this.state.subchildren === 0 ? (descuento_adulto * tc).toFixed(2) : this.state.subadults === 0 && this.state.subchildren !== 0 ? (((parseInt(this.state.subchildren) * descuento_menor) * tc) + descuento_adulto * tc) : (this.state.subadults * descuento_adulto) + (this.state.subchildren * descuento_menor * tc)}
                                                                displayType={"text"}
                                                                thousandSeparator={true}
                                                                prefix={"$"}
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                            /> : 
                                                            <NumberFormat
                                                                value={this.state.subadults === 0 && this.state.subchildren === 0 ? (precio_adulto * tc).toFixed(2) : this.state.subadults === 0 && this.state.subchildren !== 0 ? (parseInt(this.state.subchildren) * (precio_menor * tc) + (precio_adulto * tc)) : (this.state.subadults * (precio_adulto + (this.state.subchildren * (precio_menor * tc))))}
                                                                displayType={"text"}
                                                                thousandSeparator={true}
                                                                prefix={"$"}
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                            />}
                                                        </h3> }
                                                </div>
                                            </div>
                                        </div> : null}
                                        <div className="white-space-8"></div>
                                        <div className="justify-center">
                                            <p className="color-red">
												<FormattedMessage defaultMessage="Precios en" id="precios_en" />
												&nbsp; {exrate}
											</p>
                                        </div>
                                        <div className="white-space-16"></div>
                                        <div className="justify-center align-center">
                                            {this.state.fecha ?
                                            <button className="btn btn-buy" type="submit">
                                                <FormattedMessage defaultMessage="Agregar al carrito" id="producto_agregar_al_carrito" />
                                            </button> :
                                            <button className="btn btn-disabled-buy" type="submit" disabled>
                                                <FormattedMessage defaultMessage="Agregar al carrito" id="producto_agregar_al_carrito" />
                                            </button>}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="no-result column justify-center align-center">
                    <div className="white-space-32"></div>
                    <h2 className="color-black">No se encontraron resultados.</h2>    
                </div>}
                <div className="white-space-48"></div>
                <div className={`sl-footer align-center ${this.state.activo ? null : 'fix'}`}>
                    <Footer />
                </div>
            </div>
        )
    }
}

export default Consumer(injectIntl(ProductDetail));
