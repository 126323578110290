/**
 *  home.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio - kath
 *  @description: Página inicial
*/

import React, { Component } from 'react';
import { Consumer } from "../context";
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from "react-router-dom";
import OwlCarousel from 'react-owl-carousel2';
import Header from '../components/header/header';
import Slider from '../pages/slider-m';
import CardChat from '../components/cards/card-chat';
import CardConversation from '../components/cards/card-conversation';
import Footer from '../components/footer/footer';
import jquery from 'jquery';
import Request from '../core/httpClient';
import BrowserDetection from 'react-browser-detection';
import { BrowserView, MobileView, isMobile, CustomView, browserName, isIOS, mobileVendor, mobileModel, MobileOnlyView, osName } from "react-device-detect";
import ModalBanner from '../components/modals/banner';
import onClickOutside from 'react-onclickoutside';

// Images
import Waves from '../../src/img/waves.png';
import Img60 from '../../src/img/diversion.jpg';
import Img40 from '../../src/img/aventura.png';
import Jungle from '../../src/img/jungle.jpg';
import CultureMaya from '../../src/img/top.jpeg';
import History from '../../src/img/piramide.jpg';
import VIP from '../../src/img/yacht.jpg';
import playa from '../../src/img/playa.jpg';
import Imagen1 from '../img/slider/1.jpg';
import Imagen13 from '../img/slider/13.jpg';
import Imagen14 from '../img/slider/14.jpg';
import Imagen15 from '../img/slider/15.jpg';

// Slider Images (FAKE)
import Imagen3 from '../img/view.jpg';

// Video
import VideoVC from '../../src/video/VideoV.1.mp4';
import CatalogViewGrid from '../components/catalog/catalog-view-grid';

const req = new Request();

global.jQuery 	= jquery;
global.$ 		= jquery;

/*const browserHandler = {
	safari: () => 	null,
	chrome: () => 	<div className="video-container">
						<video autoPlay loop muted src={VideoVC} className="video"></video>
					</div>,
	default: (browser) => 	<div className="video-container">
								<video autoPlay loop muted src={VideoVC} className="video"></video>
							</div>
};*/

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			products: [],
			categories: [],
			sliderProducts: [],
			clasifications: false,
			user: []
		}
	}

	handleClickOutside = (evt) => {
		console.log('onClickOutside() method called');
	}

	async load() {
		let data = 0;
		let locale = window.localStorage.getItem('cto_locale');
		console.log('locate',locale);

		let user = window.localStorage.getItem("tousr");
		console.log('user', user);
		if (user) {
			user = JSON.parse(user);

			const resUser = await req.post('/usuarios/tokuser', { id: user.id });
			if (resUser) {
				data = {
					id_agencia: resUser.data.agencia
				}
			}
		} else {
			data = {
				id_agencia: 0
			}
		}

		const res = await req.post('/products/home', data);
		if(res.products) {
			console.log(res);
			const relevantProducts = res.products.filter(item => item.relevante >= 1);
			
			let packs = res.products.filter(product => parseInt(product.categoria) === 30);

			console.log(packs);

			this.setState({products: relevantProducts, sliderProducts: packs});
		}

		const clasificaciones = await req.get('/clasificaciones/');
		if (clasificaciones.clasifications) {
			this.setState({ clasifications: clasificaciones.clasifications });
		}

		const resBanner = await req.get('/products/banner');
		console.log(resBanner);
		if(resBanner.data) {
			if (resBanner.data.length !== 0) {
				let bannerImage = '';
				bannerImage = JSON.parse(resBanner.data.imagen);
				bannerImage = bannerImage[locale];

				if (bannerImage !== '') {
					this.setState({ bannerProduct: resBanner.data });
				
					if (document.getElementById("modal-banner")) {
						//Open banner
						setTimeout(() => {
							// Trigger Login Modal
							const modal = document.getElementById("modal-banner");
							if(modal) {
								modal.classList.remove("hidden");
							}
						}, 3000);
					}
				}
			}
		}
	}

	addToCart(idProduct) {
		// this.props.history.push(`/detalle/producto/${idProduct}`);
		this.props.history.push(`/catalogo/cat/30`);
	}

	changeLocale(event) {
		const locale = event;
		if (locale) {
			window.localStorage.setItem('cto_locale', locale);
			window.location.reload();
		}
	}

	showMore() {
		let x = document.getElementById("more-categories");
		if (x.classList.contains('showMore')) {
			x.classList.remove('showMore');
			x.classList.add('more-categories');
		} else {
			x.classList.add('showMore');
			x.classList.remove('more-categories');
		}
	}

	catalogo(id) {
		window.localStorage.removeItem('cats');
		window.localStorage.removeItem('page');
		window.localStorage.removeItem('view');
		window.localStorage.removeItem('order');
		this.props.history.push('/catalogo/cla/' + id);
	}

	changeWindow(data) {
		this.setState({ conversation: data });
	}

	/*componentDidMount() {
		let moreCategories = document.getElementById('more-categories');
		let btnMore = document.getElementById('more-button');
		window.onclick = function (event) {
			if (event.target === moreCategories || event.target === btnMore) {
				moreCategories.classList.add('showMore');
			} else {
				moreCategories.classList.remove('showMore');
			}
		}
	}*/

	openFormChat() {
		let vc_chat = window.localStorage.getItem('vc_chat');
		if (vc_chat) {
			this.setState({ conversation: true });
		}
		const myFormConversation = document.getElementById("myFormConversation");
		const buttom_chat = document.getElementById("buttom-chat");
		const chat_scd = document.getElementById("chat-scd");
		const myForm = document.getElementById("myForm");
		//Abrir chat
		if (this.state.conversation) {

			if(myFormConversation) {
				myFormConversation.style.display = "block";
			}
			if(buttom_chat) {
				buttom_chat.style.display = "none";
			}
			if(chat_scd) {
				chat_scd.style.bottom = "0";
			}	
		} else {
			if (myForm) {
				myForm.style.display = "block";

				if(buttom_chat) {
					buttom_chat.style.display = "none";
				}
				if(chat_scd) {
					chat_scd.style.bottom = "0";
				}
			}
		}
	}

	displayMenu() {
		let menu = document.getElementById("topnav");
		let nav = document.getElementById("nav");
		if (nav.classList.contains('bg-pink')) {
			nav.classList.remove('bg-pink');
			nav.classList.add('bg-blue');
		} else {
			nav.classList.remove('bg-blue');
			nav.classList.add('bg-pink');
		}

		if (menu.classList.contains('none')) {
			menu.classList.remove('none');
			menu.classList.add('responsive');
		} else {
			menu.className = "menu-cat none justify-center align-center";
		}
	}

	filterFromHome(ruta) {
		window.localStorage.removeItem('cats');
		window.localStorage.removeItem('page');
		window.localStorage.removeItem('view');
		window.localStorage.removeItem('order');
		this.props.history.push(ruta);
	}

	render() {
		let locale = window.localStorage.getItem('cto_locale');
		if(!locale) {
			locale = 'es';
		}

		let tc = 1;
		const exrate = window.localStorage.getItem('exrate');
		if (exrate === 'MXN') {
			tc = this.props.context.tc;
		} else if (exrate === 'USD') {
			tc = 1;
		}

		const options = {
            items: 1,
            nav: true,
			navText: [`<i class="fa fa-angle-left fa-5x"></i>`, `<i class="fa fa-angle-right fa-5x"></i>`],
			rewind: true,
            autoplay: true,
			loop: true,
			responsiveClass: true,
			autoplayTimeout: 5000,
			responsive:{
				0:{
					items:1,
					nav:false
				},
				600:{
					items:1,
					nav:false
				},
				1000: {
					items:1,
					nav:false
				},
				1300:{
					items:1,
					nav:true,
					loop:true
				}
			}
		};

		let clasificaciones = [], totalCat = 0, slider = [];
		if (this.state.clasifications) {
			clasificaciones = this.state.clasifications;
			//console.log(this.state.clasifications.map(cat => cat).filter(item => item.clasificacion[locale].clasificacion));
			//clasificaciones = this.state.clasifications.map(cat => cat.clasificacion[locale].clasificacion);*/
			totalCat = clasificaciones.length;
		}

		if (this.state.sliderProducts) {
			slider = this.state.sliderProducts;
		}

		return (
			<div className="principal column">
				<div className="home column">
					<Header></Header>
					<div className="categories-menu full-franja justify-center">
						<div className="container">
							<div className="column menu-cat none justify-center align-center" id="topnav">
								{/*<a className="bg-pink icon text-center" onClick={this.displayMenu.bind(this)} id="nav">
									<i className="fa fa-bars"></i>
								</a>
								{clasificaciones.slice(0,12).map((cat, index) => 
									<a className="categorie-item text-center" key={index} onClick={this.catalogo.bind(this, cat.id_clasificacion)}>{cat.clasificacion[locale].clasificacion}</a>
								)}*/}
								<div className="white-space-8"></div>
								<h4 className="text-center color-white weight-bold"><FormattedMessage defaultMessage="TOURS IDEALES PARA LAS VACACIONES PERFECTAS" id="franja" /></h4>
								<div className="white-space-8"></div>
							</div>
						</div>
					</div>
					{/*<BrowserDetection>
						{browserHandler}
					</BrowserDetection>*/}
					{isMobile ? 
						osName !== "iOS" ?
						<CustomView condition={osName !== "iOS"}>
							<div className="video-container">
								<video autoPlay loop muted src={VideoVC} className="video"></video>
							</div>
						</CustomView> :
						<div className="slid">
							<Slider page={'home'}></Slider>
						</div>
					:
						<div className="video-container">
							<video autoPlay loop muted src={VideoVC} className="video"></video>
						</div>
					}
					{/*<div className="categories">
						<div className="w3-bar align-center">
							<div className="categories-left align-center">
								{categorias.slice(0,4).map((cat, index) => 
									<a className="w3-bar-item categorie-item text-center" onClick={this.catalogo.bind(this)}>{cat}</a>
								)}
								{totalCat > 4 ?
								<div className="more justify-center align-center">
									<a className="more-button text-center" onClick={this.showMore.bind(this)}>
										<FormattedMessage defaultMessage="Más" id="home_more_categorias" /><i class="fa fa-caret-down"></i></a>
									<div id="more-categories" className="more-categories column">
										{categorias.slice(4, totalCat).map((cat, index) =>
											<a className="w3-bar-item categorie-item" onClick={this.catalogo.bind(this)}>{cat}</a>
										)}
									</div>
								</div> : null}
							</div>
						</div>
					</div>*/}
					<div className="categories-menu justify-center">
						<div className="container">
							<div className="column menu-cat none justify-center align-center" id="topnav">
								<div className="white-space-8"></div>
								<h4 className="text-center color-pink weight-bold"><FormattedMessage defaultMessage="COMPRA MÁS DE 1 Y PAGA MENOS" id="compra_mas" /></h4>
								<h4 className="text-center color-pink weight-bold"><FormattedMessage defaultMessage="MIENTRAS MÁS COMPRAS MÁS DESCUENTOS Y REGALOS PARA TI" id="mientras_mas_compras" /></h4>
								<div className="white-space-8"></div>
							</div>
						</div>
					</div>
					<div className="column align-center links-main"> 
						<div className="row-responsive container justify-arround">
							<div className="row first justify-between">
								<div className="nav-item auto">
									<Link /*to={`/catalogo/all/${0}`}*/ className="weight-semi" onClick={this.filterFromHome.bind(this, `/catalogo/all/${0}`)}>
										<FormattedMessage defaultMessage="Actividades" id="header_actividades" />
									</Link>
								</div>
								<div className="nav-item auto">
									<Link className="weight-semi" onClick={this.filterFromHome.bind(this, `/catalogo/cat/${29}`)}>
										<FormattedMessage defaultMessage="Xcaret" id="header_xcaret" />
									</Link>
								</div>
								<div className="nav-item auto">
									<Link className="weight-semi" onClick={this.filterFromHome.bind(this, `/catalogo/cat/${31}`)}>
										<FormattedMessage defaultMessage="Tours privados" id="header_tour_privado" />
									</Link>
								</div>
							</div>
							<div className="row second justify-center">
								<div className="container justify-between">
									<div className="nav-item auto">
										<Link to="/nosotros" className="weight-semi">
											<FormattedMessage defaultMessage="Nosotros" id="header_nosotros" />
										</Link>
									</div>
									<div className="nav-item auto">
										<Link to="/contacto" className="weight-semi">
											<FormattedMessage defaultMessage="Contacto" id="contact" />
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="offers-gral column">
						{/*<div className="offers justify-center">
							<div className="container column main-offer">
								<div className="white-space-48"></div>
								<div className="card-category">
									<Link className="card-60 responsive-img card-cont" onClick={this.filterFromHome.bind(this, `/catalogo/cat/${17}`)} //to={`/catalogo/cat/${17}`}>
										<div className="image column-image">
											<figure><img src={Img60} alt="Diversión" title="Diversión" /></figure>
										</div>
										<div className="title">
											<p className="color-white">
												<FormattedMessage defaultMessage="Diversión" id="categoria_diversion" />
											</p>
										</div>
									</Link>
									<Link className="card-40 responsive-img card-cont" onClick={this.filterFromHome.bind(this, `/catalogo/cat/${18}`)} //to={`/catalogo/cat/${18}`}>
										<div className="image column-image">
											<figure><img src={Img40} alt="Aventura" title="Aventura" /></figure>
										</div>
										<div className="title">
											<p className="color-white">
												<FormattedMessage defaultMessage="Aventura" id="categoria_aventura" />
											</p>
										</div>
									</Link>
								</div>
								<div className="white-space-16"></div>
								<div className="card-category invert">
									<Link className="card-60-320 responsive-img card-cont" onClick={this.filterFromHome.bind(this, `/catalogo/cat/${19}`)} //to={`/catalogo/cat/${19}`}>
										<div className="image column-image">
											<figure><img src={VIP} alt="VIP" title="VIP" /></figure>
										</div>
										<div className="title">
											<p className="color-white">
												<FormattedMessage defaultMessage="Servicios VIP" id="categoria_VIP" />
											</p>
										</div>
									</Link>
									<Link className="card-40-320 responsive-img card-cont" onClick={this.filterFromHome.bind(this, `/catalogo/cat/${20}`)} //to={`/catalogo/cat/${20}`}>
										<div className="image column-image">
											<figure><img src={History} alt="History" title="History" /></figure>
										</div>
										<div className="title tittle-content">
											<p className="color-white">
												<FormattedMessage defaultMessage="Historia" id="categoria_historia" />
											</p>
										</div>
									</Link>
								</div>
								<div className="white-space-16"></div>
								<div className="card-category third">
									<Link className="card-100 responsive-img card-cont" onClick={this.filterFromHome.bind(this, `/catalogo/cat/${10}`)} //to={`/catalogo/cat/${10}`}>
										<div className="image column-image">
											<figure><img src={CultureMaya} alt="Top 10" title="Top 10" /></figure>
										</div>
										<div className="title">
											<p className="color-white">
												<FormattedMessage defaultMessage="Top 10" id="top" />
											</p>
										</div>
									</Link>
								</div>
								<div className="white-space-48"></div>
							</div>
						</div>*/}
						<div className="best-offers justify-center">
							<div className="container container-slide column">
								{this.state.sliderProducts.length > 0 ?
								<React.Fragment>
									{/*<div className="white-space-48"></div>*/}
									<div className="column packs">
										<OwlCarousel options={options}>
											{slider.map((product, index) => {
												let image = '';
												if(product.slider) {
													image = JSON.parse(product.slider);
													image = image[locale];

													return <div className="item-promotion align-center" key={product.id_producto} onClick={this.filterFromHome.bind(this, `/catalogo/all/${30}`)}>
													<img src={image} alt="Mirror Edge" />
													<div className="rd-parallax-layer">
														<div className="bg-overlay-darker align-start">
															<div className="top-text justify-end align-center row">
																{/*<div className="btn btn-reservation justify-center align-center" key={product.id_producto} onClick={this.addToCart.bind(this, product.id_producto)}>{/*<a onClick={this.addToCart.bind(this, product)} className="btn btn-reservation text-center"><i className="fas fa-shopping-cart"></i>&nbsp;<p className="color-white"> Reservar</p></div>*/}
															</div>
														</div>
													</div>
												</div>
												}
											}
											)}
											{/*<div className="item-promotion align-center">
												<img src={Imagen1} alt="Mirror Edge" />
												<div className="rd-parallax-layer">
													<div className="bg-overlay-darker align-start">
														<div className="top-text justify-end align-center">
															<a onClick={this.addToCart.bind(this)} className="btn btn-reservation text-center"><i class="fas fa-shopping-cart"></i>&nbsp; Reservar</a>
														</div>
													</div>
												</div>
											</div>
											<div className="item-promotion">
												<img src={Imagen13} alt="GTA V" />
												<div className="rd-parallax-layer">
													<div className="bg-overlay-darker align-start">
														<div className="top-text justify-end align-center">
															<a onClick={this.addToCart.bind(this)} className="btn btn-reservation text-center"><i class="fas fa-shopping-cart"></i>&nbsp; Reservar</a>
														</div>
													</div>
												</div>
											</div>
											<div className="item-promotion">
												<img src={Imagen15} alt="Mirror Edge" />
												<div className="rd-parallax-layer">
													<div className="bg-overlay-darker align-start">
														<div className="top-text justify-end align-center">
															<a onClick={this.addToCart.bind(this)} className="btn btn-reservation text-center"><i class="fas fa-shopping-cart"></i>&nbsp; Reservar</a>
														</div>
													</div>
												</div>
											</div>*/}
										</OwlCarousel> 
									</div>
								</React.Fragment> : <div></div>}
								{/*{this.state.products.length > 0 ?
									<OwlCarousel options={options} >
										{this.state.products.map((item, index) => {
											let titulo, descripcion, descuento;
											if (item.titulo) {
												titulo = JSON.parse(item.titulo);
												titulo = titulo[locale];
											}
											if (item.descripcion_corta) {
												descripcion = JSON.parse(item.descripcion_corta);
												descripcion = descripcion[locale];
											}
											if (item.descuento) {
												descuento = item.descuento;
												console.log(descuento);
											}

											return (
												<a href={`/detalle/producto/${item.id_producto}`} key={index}>
													<div className="item column align-end">
														<div className="image responsive-img justify-center align-center">
															<img src={item.imagen} alt="Mirror Edge" className="" />
															<div className="overlay">

															</div>
														</div>
														<div className="info column justify-between">
															<div className="title column">
																<h3>
																	{titulo}
																</h3>
																<div className="white-space-8"></div>
																<h4>
																	{descripcion}
																</h4>
															</div>
															<div className="controlls justify-between">
																{/*<div className="view">
															<Link to={`/detalle/producto/${item.id_producto}`} className="btn-view" key={index}>
																<FormattedMessage defaultMessage="Ver más" id="ver_mas" />
															</Link>
														</div>//
																<div className="price align-center justify-end">
																	<p className="exrate">{exrate}</p>
																	<p className="price-number text-right weight-semi color-blue">
																		${(item.precio_adulto * tc).toFixed(2)}
																	</p>
																</div>
															</div>
														</div>
														{descuento !== null && descuento > 0 ?
														<div className="ribbon-wrapper-red">
															<div className="ribbon-red"><FormattedMessage defaultMessage="OFERTA" id="producto_oferta" /></div>
														</div> : null}
													</div>
												</a>)
											})
										}
									</OwlCarousel>
									: null}*/}
							</div>
						</div>
						<div className="white-space-8"></div>
						<div className="categories-menu justify-center">
							<div className="container">
								<div className="column menu-cat none justify-center align-center" id="topnav">
									<div className="white-space-8"></div>
									<h4 className="text-center color-pink weight-bold"><FormattedMessage defaultMessage="RESERVA AHORA CON INCREÍBLES PRECIOS" id="reserva_increibles_precios" /></h4>
									<div className="white-space-16"></div>
								</div>
							</div>
						</div>
						{/*<div className="white-space-24"></div>*/}
						<div className="catalog-home align-center column">
							<div className="container">
								<CatalogViewGrid page={1} products={this.state.products}></CatalogViewGrid>
							</div>
						</div>
						<div className="white-space-64"></div>
					</div>
					{/* Best Offers */}
					{/*
					<div className="column">
						<div className="offers-cards justify-center">
							<div className="container">
								<div className="cards align-center justify-center">
									{this.state.products.map((tour, index) => 
										<Link to={`/detalle/producto/${tour.id_producto}`} key={index}><CardOffer {...tour} locale={locale}></CardOffer></Link>
									)}
								</div>
							</div>
						</div>
					</div>
					*/}										
					<Footer />
				</div>
				<ModalBanner productsB={this.state.bannerProduct} locale={locale} />
				{/*<div className="chat justify-center">
					<div className="chat-scd" id="chat-scd">
						<div className="buttom-chat justify-end" id="buttom-chat">
							<a onClick={this.openFormChat.bind(this)}>
								<div className="chat-btn justify-center align-center">
									<i className="fas fa-comments"></i>
								</div>
							</a>
						</div>
						{this.state.conversation === false ? 
						<CardChat changeWindow={this.changeWindow.bind(this)} /> :
						<CardConversation changeWindow={this.changeWindow.bind(this)} /> }
					</div>
				</div>*/}
				{/*<button onClick={() => socket.emit('saludar', {saludo: 'olaaa'})}>Manda wey</button>*/}
			</div>
		)
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	
		this.load();

		//Open chats
		setTimeout(() => {
			this.openFormChat();
		}, 30000);
	}
}

export default Consumer(injectIntl(Home));