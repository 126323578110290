import React, { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import Logo from '../../img/Imagotipo.png';

class Footer extends Component {
    render() {
        return (
            <footer className="column">
                <div className="footer-top justify-center">
                    <div className="container">
                        <div className="row-responsive">
                            <div className="left row align-center">
                                <div className="image responsive-img">
                                    <img src={Logo} alt="Vamos Cancún Logo" title="Vamos Cancún Logo"/>
                                </div>
                                <div className="social justify-center">
                                    <div className="icon auto">
                                        <a href="https://www.facebook.com/VamosCancunMX/" target="_blank" rel="noopener">
                                            <i className="fab fa-facebook-square color-blue fa-2x"></i>
                                        </a>
                                    </div>
                                    <div className="icon auto">
                                        <a href="https://www.instagram.com/vamoscancunmx/?hl=es-la" target="_blank" rel="noopener">
                                            <i className="fab fa-instagram color-blue fa-2x"></i>
                                        </a>
                                    </div>
                                    <div className="icon auto">
                                        <a href="https://www.tripadvisor.com.mx/Attraction_Review-g150807-d19078896-Reviews-Vamos_Cancun-Cancun_Yucatan_Peninsula.html?m=19905" target="_blank" rel="noopener">
                                            <i className="fab fa-tripadvisor color-blue fa-2x"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="right align-center">
                                <div className="row-responsive">
                                    <div className="links row">                                     
                                        <div className="link justify-center">
                                            <Link to="/nosotros">
                                                <i className="fas fa-angle-right"></i> Acerca de
                                            </Link>
                                        </div>
                                        
                                        <div className="link-right justify-center">
                                            <Link to="/terminos">
                                                <i className="fas fa-angle-right"></i> Términos y condiciones
                                            </Link>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

{/*
<div className="left-f column">
    <div className="text-f justify-center">
        <p>
            <FormattedMessage defaultMessage="Políticas de compra y devoluciones" id="footer_politicas" />
        </p>
    </div>
</div>
<div className="right-f column">
    <div className="text-f justify-center">
        <h4>
            <FormattedMessage defaultMessage="CONTACTO" id="footer_contacto" />
        </h4>
    </div>
    <div className="text-f justify-center">
        <h4>
            <FormattedMessage defaultMessage="Correo: " id="footer_correo" />
            <a href="mailto:ventas@sololuk.com">tours@vamoscancun.com</a>
        </h4>
    </div>
</div>
*/}

export default injectIntl(Footer);