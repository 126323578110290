import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import CreditCards from '../../img/cards5.png';
import DebitCards from '../../img/debitcards.png';
import OpenPay from '../../img/openpay.png';
import Security from '../../img/security.png'
import { Link } from "react-router-dom";

class PayData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cartIcon: ""
        };
    }

    cardChange(event) {
        const type = window.OpenPay.card.cardType(event.target.value);
        let clase = "";
        if (type.indexOf('Visa') !== -1) {
            clase = "fab fa-cc-visa";
        }
        if (type.indexOf('Mastercard') !== -1) {
            clase = "fab fa-cc-mastercard";
        }
        if (type.indexOf('American Express') !== -1) {
            clase = "fab fa-cc-amex";
        }
        if (type.indexOf('Diners') !== -1) {
            clase = "fab fa-cc-diners-club";
        }
        if (type.indexOf('Discover') !== -1) {
            clase = "fab fa-cc-discover";
        }
        if (type.indexOf('JCB') !== -1) {
            clase = "fab fa-cc-jcb";
        }
        this.setState({ cardIcon: <i className={clase} /> });
    }

    render() {
        return (
            <div className="paydata column inputs-c">
                <div className="cardstype row">
                    <div className="creditcard column align-start">
                        <h4>
                            <FormattedMessage defaultMessage="Tarjetas de crédito: " id="pago_tarjeta_credito" />
                        </h4>
                        <div className="cimage responsive-img">
                            <img src={CreditCards} />
                        </div>
                    </div>
                    <div className="debitcard column align-start">
                        <h4>
                            <FormattedMessage defaultMessage="Tarjetas de débito: " id="pago_tarjeta_debito" />
                        </h4>
                        <div className="cimage responsive-img">
                            <img src={DebitCards} />
                        </div>
                    </div>
                </div>
                <div className="white-space-16"></div>
                <div className="input-dp">
                    <FormattedMessage id="pago_nombre" defaultMessage="NOMBRE DEL TITULAR DE TARJETA">
                        {(message) => <input className="input-pay" name="holder_name" type="text" placeholder={message} maxLength="48" data-openpay-card="holder_name" required />}
                    </FormattedMessage>
                </div>
                <div className="white-space-16"></div>
                <div className="row input-card">
                    {this.state.cardIcon}
                    <input className="input-pay" id="card_number" name="card_number" type="tel" placeholder="XXXX XXXX XXXX XXXX" maxLength="16" data-openpay-card="card_number" onChange={this.cardChange.bind(this)} required />
                </div>
                <div className="white-space-16"></div>
                <div className="row">
                    <div className="input-c">
                        <input className="input-pay input-left" id="expiration_month" name="expiration_month" type="text" placeholder="MM" maxLength="2" data-openpay-card="expiration_month" required />
                    </div>
                    <div className="input-c">
                        <input className="input" id="expiration_year" name="expiration_year" type="text" placeholder="AA" maxLength="2" data-openpay-card="expiration_year" required />
                    </div>
                    <div className="input-t">
                        <input className="input-pay input-right" id="cvv" name="cvv" type="text" placeholder="CVV" maxLength="4" data-openpay-card="cvv2" required />
                    </div>
                </div>
                <div className="white-space-24"></div>
                <div className="info-openpay row">
                    <div className="openpay column align-center">
                        <h4>
                            <FormattedMessage defaultMessage="Transacciones realizadas vía: " id="pago_transacciones" />
                        </h4>
                        <div className="oimage responsive-img justify-center">
                            <img src={OpenPay} />
                        </div>
                    </div>
                    <div className="security align-center justify-center">
                        <div className="oimage responsive-img justify-center">
                            <img src={Security} />
                        </div>
                    </div>
                </div>
                <div className="white-space-24"></div>
                <div className="tyc row align-center">
                    <input type="checkbox" name="tyc" id="tyc" required/>
                    <Link to="/terminos" target="_blank">
                        <p><FormattedMessage defaultMessage="He leído términos y condiciones." id="pago_tyc" /></p>
                    </Link>
                </div>
                {/*<div className="white-space-48"></div>
                <div className="pay-button justify-center align-center">
                    <button className="btn btn-pay">
                        <FormattedMessage defaultMessage="PAGAR" id="pago_boton" />
                    </button>
                </div>*/}
            </div>
        );
    }
}

export default injectIntl(PayData);