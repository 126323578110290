import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import socket from '../../core/socket';

class CardConversation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: []
        }
    }

    componentDidMount() {
        socket.on('msg_success', (data) => {
            console.log(data);
        });

        socket.on('msg_admin', (data) => {
            if(data) {
                const mensaje = JSON.parse(data);
                const mensajes = this.state.message;
                mensajes.push(mensaje);
                this.setState({ message: mensajes });
                this.scrollChat();
            }
        });

        let id_chat = window.localStorage.getItem('vc_chat');
        if(id_chat) {
            socket.emit('loadMessages', {id_chat: JSON.parse(id_chat)});
        }

        socket.on('loadMessages', (data) => {
            if(data.mensajes) {
                this.setState({status: data.mensajes[0].status});
                if(data.mensajes.length > 0) {
                    let mensajes = this.state.message;
                    data.mensajes.forEach(mensaje => {
                        mensajes.push(JSON.parse(mensaje.mensajes));
                    });
                    mensajes = mensajes.sort((a,b) => {
                        if(a.fecha_envio > b.fecha_envio) {
                            return 1;
                        }
                        if(a.fecha_envio < b.fecha_envio) {
                            return -1;
                        }
                        return 0;
                    })
                    this.setState({message: mensajes});
                    this.scrollChat();
                }
            }
        });
    }

    closeFormChat() {
        document.getElementById("myFormConversation").style.display = "none";
        document.getElementById("buttom-chat").style.display = "flex";
        document.getElementById("chat-scd").style.bottom = "90px";
        this.props.changeWindow(false);
    }

    closeChat() {
        document.getElementById("myFormConversation").style.display = "none";
        document.getElementById("buttom-chat").style.display = "flex";
        document.getElementById("chat-scd").style.bottom = "90px";
        this.props.changeWindow(false);
        window.localStorage.removeItem('vc_chat');
    }

    newMessage(e) {
        e.preventDefault();

        const form = e.target;
            if(form) {
            let id_chat = window.localStorage.getItem('vc_chat');
            if (id_chat) {
                id_chat = JSON.parse(id_chat);
            }

            const data = {
                mensaje: form.msg.value,
                id_chat: id_chat,
                recibiendo: 'admin',
                enviando: id_chat,
                nuevo: 1
            };
            form.msg.value = "";

            socket.emit('msg', data);
        }
    }

    scrollChat() {
        const chat_messages = document.getElementById('chat_messages');
        if(chat_messages) {
            const nodes = chat_messages.querySelectorAll('div');
            const last = nodes[nodes.length- 1];
            // console.log(last.scrollHeight);
            chat_messages.scrollTop = last.scrollIntoView();
        }
    }

    render() {
        return (
            <div className="conversation-popup align-center justify-center" id="myFormConversation">
                <div className="popup justify-end">
                    <form onSubmit={this.newMessage.bind(this)} className="form-container column">
                        <div className="fc-title column">
                            <div className="close justify-end">
                                <a onClick={this.closeFormChat.bind(this)}>
                                    <i className="fas fa-times color-white"></i>
                                </a>
                            </div>
                        </div>
                        <div className="white-space-16"></div>
                        <div className="fc-inputs justify-center">
                            <div className="container content-chat">
                                <div className="column"  id="chat_messages">
                                    <div className="white-space-16"></div>
                                    {this.state.message.map((data, index) => {
                                        if(data.enviando !== 'admin' && data.recibiendo !== 'info') {
                                            return (
                                                <React.Fragment key={index}>
                                                    <div className="message-me justify-end" key={index}>
                                                        <p className="auto text-left">{data.msg}</p>
                                                    </div>
                                                    <div className="white-space-16"></div>
                                                </React.Fragment>
                                            )
                                        } else {
                                            return (
                                                <React.Fragment key={index}>
                                                    <div className="message-user justify-start">
                                                        <p className="auto text-right">{data.msg}</p>
                                                    </div>
                                                    <div className="white-space-16"></div>
                                                </React.Fragment>
                                            )
                                        }
                                    })}
                                    {this.state.status === 'CERRADO'?
                                        <React.Fragment>
                                            <div className="justify-center">
                                                <a className="auto text-right" onClick={this.closeChat.bind(this)}>
                                                    <FormattedMessage id="cerrado" defaultMessage="CERRADO"/>
                                                </a>
                                            </div>
                                            <div className="white-space-16"></div>
                                        </React.Fragment>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="white-space-16"></div>
                        <div className="fc-button justify-center">
                            {this.state.status === 'CERRADO'? 
                            <div/>
                            :
                            <div className="container container-controls">
                                <div className="left">
                                    <FormattedMessage id="chat_escribir" defaultMessage="Escribe un mensaje">
                                        {(message) => <input type="text" autoComplete="none" name="msg" className="input input-left" placeholder={message} />}
                                    </FormattedMessage>
                                </div>
                                <div className="right auto align-center">
                                    <button type="submit" className="btn-little-send">
                                        <i className="fab fa-telegram-plane font-regular"></i>
                                    </button>
                                </div>
                            </div>
                            }
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default injectIntl(CardConversation);