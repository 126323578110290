export function saveReservation(data) {
    this.setState({ reservation: data });
};

export function addtoCart(item) {
    //let localCart = this.state.cart;
    let localCart = window.localStorage.getItem('cart');
    if (localCart) {
        localCart = JSON.parse(localCart);
        localCart.push(item);
        console.log("localCart", localCart);
        let discount = 0;

        if (localCart.length !== 0) {
            if (localCart[0].agencia) {
                if (localCart.length > 2) {
                    console.log("10%")
                    discount = 0.9;
                } else if (localCart.length == 2) {
                    console.log("5%")
                    discount = 0.95;
                }

                if (discount !== 0) {
                    localCart.map((item, i) => {
                        let total = (item.product.precio_adulto * item.adultos) + (item.product.precio_menor * item.menores);
                        item.total = Math.ceil(total * discount);
                        console.log("Total: ", total, "* ", discount);
                    })
                    console.log("localCartFinal", localCart);
                }
            }
        }
        
    } else {
        localCart = [item];
    }

    //Object.assign(item);
    
    this.setState({ cart: localCart });
    window.localStorage.setItem('cart', JSON.stringify(localCart));

    return localCart;
}

export function removeFromCart(item, pIndex) {
    //let localCart = this.state.cart;
    let localCart = window.localStorage.getItem('cart');
    if (localCart) {
        localCart = JSON.parse(localCart);
        localCart = localCart.filter((data, index) => index !== pIndex);

        let discount = 0;

        if (localCart.length !== 0) {
            if (localCart[0].agencia) {
                if (localCart.length > 2) {
                    console.log("10%")
                    discount = 0.9;
                } else if (localCart.length == 2) {
                    console.log("5%")
                    discount = 0.95;
                }

                if (discount !== 0) {
                    localCart.map((item, i) => {
                        let total = (item.product.precio_adulto * item.adultos) + (item.product.precio_menor * item.menores);
                        item.total = Math.ceil(total * discount);
                        console.log("Total: ", total, "* ", discount);
                    })
                    console.log("localCartFinal", localCart);
                }
            }
        }

        this.setState({ cart: localCart });
        window.localStorage.setItem('cart', JSON.stringify(localCart));
    }
    
    return localCart;
}

export function saveIdChat(id) {
    window.localStorage.setItem('vc_chat', JSON.stringify(id));
    this.setState({ chat: id });
}

export function cleanCart() {
    this.setState({ cart: [] });
    window.localStorage.setItem('cart', []);
}

export function getTC(tc) {
    this.setState({ tc });
}