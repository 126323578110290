import React, { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import InputRange from 'react-input-range';
import {Consumer} from '../context';
import Header from '../components/header/header';
import TopOfPage from '../components/slider/top-of-page';
import Footer from '../components/footer/footer';
import Imagen from '../img/1-tour.jpg';
import CatalogViewGrid from '../components/catalog/catalog-view-grid';
import CatalogViewList from '../components/catalog/catalog-view-list';
import Request from '../core/httpClient';
import 'react-input-range/lib/css/index.css';
import Paginator from '../components/paginator/paginator';
import { fcall } from "q";
import playa from '../../src/img/about-us/playa3.jpg';

const req = new Request();

function MaysPrimera(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

class Catalog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            view: 1,
            categories: [],
            products: [],
            filterName: '',
            range: {min: 0, max: 5000},
            filterRange: {min: 0, max: 5000},
            filterCats: [],
            orden: false,
            allProducts: [],
            clasifications: [],
            clasificacion: 0,
            optionOrder: ''
        }
    }

    componentDidMount() {
        const page = window.localStorage.getItem('page');
        if (page) {
            this.setState({ page });
        }

        let view = window.localStorage.getItem('view');
        if (view) {
            view = parseInt(view);
            this.setState({ view });
        }

        this.loadProducts('');
        this.loadCategories();
    }

    async loadProducts(data) {
        let locale = window.localStorage.getItem('cto_locale');
        if (!locale) {
            locale = 'es';
        }

        let user = window.localStorage.getItem("tousr");

        if (user) {
            user = JSON.parse(user);

            const resUser = await req.post('/usuarios/tokuser', { id: user.id });
            if (resUser) {
                data = {
                    id_agencia: resUser.data.agencia
                }
            }
        } else {
            data = {
                id_agencia: 0
            }
        }
        console.log("Data: ", data);

        const res = await req.post('/products/home', data);
        if (res.products) {
            this.setState({ products: res.products, allProducts: res.products });
        }

        let order = window.localStorage.getItem('order');
        if (order) {
            order = order.toString();
            console.log(order);
            this.orderBy(order);
		}

		const exrate = window.localStorage.getItem('exrate');
        if(exrate === 'MXN') {
			const value = {min: 0, max: 40000}
            this.setState({range: value, filterRange: value});
        } else if(exrate === 'USD') {
			const value = {min: 0, max: 5000}
			this.setState({range: value, filterRange: value});
        }
    }

    async loadCategories() {
        const categories = await req.post('/admin/categories/get/');
        if (categories.categories) {
            // console.log("Categorías", categories.categories);
            this.setState({ categories: categories.categories });
        }

        //Categorías de localStorage
        let filterCat = window.localStorage.getItem('cats');

        const filtro = this.props.match.params.filtro;
        const tipo = this.props.match.params.tipo;
        if (parseInt(filtro) !== 0) {
            if (tipo === 'cla') {
                console.log("Clasificación");
                this.setState({ clasificacion: parseInt(filtro) });
            } else {
                if (parseInt(filtro) !== 10) {
                    console.log("Categoría");
                    let cats = [];
                    if (filterCat) {
                        filterCat = JSON.parse(filterCat);
                        cats = filterCat;
                    }
                    cats.push(filtro);

                    const checkbox = document.getElementsByName('filter_cat');
                    checkbox.forEach(input => {
                        cats.forEach(cat => {
                            if (input.value === cat) {
                                input.checked = true;
                            }
                        })
                    });

                    this.setState({ filterCats: cats });
                } else {
                    console.log("Top ten");
                    const res = await req.get('/products/topten');
                    let productos = [];
                    if (res.products) {
                        console.log(res.products);
                        res.products.forEach(product =>
                            this.state.products.forEach(item => {
                                if (product.id_producto === item.id_producto) {
                                    console.log(item);
                                    productos.push(item);
                                }
                            })
                        )
                        this.setState({ products: productos, });
                    }
                }
            }
        } else {
            let cats = [];
            if (filterCat) {
                filterCat = JSON.parse(filterCat);
                cats = filterCat;

                const checkbox = document.getElementsByName('filter_cat');
                checkbox.forEach(input => {
                    cats.forEach(cat => {
                        if (input.value === cat) {
                            input.checked = true;
                        }
                    })
                });

                this.setState({ filterCats: cats });
            }
        }
    }

    filters(e) {
        e.preventDefault();

        window.localStorage.setItem('page', 1);

        const filtro = this.props.match.params.filtro;
        if (parseInt(filtro) !== 0) {
            if (parseInt(filtro) === 10) {
                this.setState({ products: this.state.allProducts });
            }
        }

        const form = e.target;
        const checkbox = document.getElementsByName('filter_cat');
        const cats = [];
        checkbox.forEach(input => {
            if(input.checked) {
                cats.push(input.value);
            }
        });
        
        this.setState({
            filterName: form.tour_name.value,
            filterRange: this.state.range,
            filterCats: cats,
            page: 1
        });

        window.localStorage.setItem('cats', JSON.stringify(cats));
    }

    changeView(type) {
        this.setState({ view: type });
        window.localStorage.setItem('view', type);
        console.log("View: ", type);
    }

    setpage(page) {
        this.setState({ page: page });
    }

    onChangeOrder(e) {
        const option = e.target.value;
        window.localStorage.setItem('order', option);
        
        this.orderBy(option);
    }

    orderBy(option) {
        let products = '';

        if (option === "popularity") {
            products = this.state.products.sort((a, b) => b.relevante - a.relevante);
        } else if (option === "new") {
            products = this.state.products.sort((a, b) => new Date(b.creado) - new Date(a.creado));
        } else if (option === "low_price") {
            products = this.state.products.sort((a, b) => a.precio_adulto - b.precio_adulto);
        } else if (option === "high_price") {
            products = this.state.products.sort((a, b) => b.precio_adulto - a.precio_adulto);
        }

        this.setState({ products, orden: true, optionOrder: option });
    }

    render() {
        let locale = window.localStorage.getItem('cto_locale');
        if (!locale) {
            locale = 'es';
        }

        let categorias = [];

        if (this.state.categories) {
            // console.log(this.state.categories.map(cat => cat.categoria[locale].categoria));
            categorias = this.state.categories.map(cat => {
                return{ name: cat.categoria[locale].categoria, value: cat.id_categoria}
            });
        }
        
        const data = { image: Imagen, text: <FormattedMessage defaultMessage="VIVE LO MEJOR DE NUESTRO MUNDO" id="catalogo_texto_imagen" />, tamaño: "350px" }

        let tc = 1;
        const exrate = window.localStorage.getItem('exrate');
        if(exrate === 'MXN') {
            tc = this.props.context.tc;
        } else if(exrate === 'USD') {
            tc = 1;
        }

        let products = [];
        if(this.state.products) {
            console.log(this.state.products);
            products = this.state.products
                .filter(product => product.titulo.toLowerCase().indexOf(this.state.filterName.toLowerCase()) !== -1)
                .filter(product => product.precio_adulto * tc > this.state.filterRange.min && product.precio_adulto * tc < this.state.filterRange.max);
            if(this.state.filterCats.length > 0) {
                products = products.filter(product => {
                    let cats = false;
                    this.state.filterCats.forEach(fCat => {
                        if(parseInt(product.categoria) === parseInt(fCat)) {
                            cats = true;
                        }
                    });
                    return cats;
                });
            }
            if (this.state.clasificacion !== 0) {
                products = products.filter(product => parseInt(product.clasificacion) === parseInt(this.state.clasificacion));
                console.log(products);
            }

            if (!this.state.orden) {
                products = products.sort((a, b) => b.relevante - a.relevante);
            }
        }

        return (
            <div className="catalog-general column">
                <Header />
                <TopOfPage data={data} />                
                <div className="catalog justify-center" style={{backgroundImage: `url(${playa})`}}>
                    <div className="container column ">
                        <div className="white-space-64"></div>
                        <div className="catalog-sections row-responsive">
                            <form className="filters-mobile column" onSubmit={this.filters.bind(this)}>
                                <div className="search-filter column">
                                    <div className="inputs">
                                        <input type="text" name="tour_name" className="input-search" placeholder="Tour" />
                                        <button className="btn btn-filter" type="submit"><i class="fas fa-search"></i></button>
                                    </div>
                                </div>
                            </form>
                            <form className="filters column" onSubmit={this.filters.bind(this)}>
                                <div className="search-filter column">
                                    <div className="title">
                                        <h4><FormattedMessage defaultMessage="BUSCAR" id="catalogo_buscar" /></h4>
                                    </div>
                                    <div className="white-space-16"></div>
                                    <div className="inputs">
                                        <input type="text" name="tour_name" className="input-search" placeholder="Tour"/>
                                    </div>
                                </div>
                                <div className="white-space-24"></div>
                                <div className="price-range column">
                                    <div className="title">
                                        <h4><FormattedMessage defaultMessage="RANGO DE PRECIO" id="catalogo_rango_precio" /></h4>
                                    </div>
                                    <div className="white-space-32"></div>
                                    <div className="sildecontainer">
                                        <InputRange
                                            className="range"
                                            name="range"
                                            id="price_range"
                                            maxValue={exrate === 'MXN'? 40000 : 5000}
                                            minValue={0}
                                            value={this.state.range}
                                            onChange={value => this.setState({ range: value })} />
                                    </div>
                                </div>
                                <div className="white-space-32"></div>
                                <div className="categories column">
                                    <div className="title">
                                        <h4><FormattedMessage defaultMessage="CATEGORÍAS" id="catalogo_categorias" /></h4>
                                    </div>
                                    <div className="white-space-16"></div>
                                    {categorias.map((item, index) =>
                                    <div className="options column" key={index}>
                                        <div className="inputGroup">
                                            <input id={"option"+index} name="filter_cat" value={item.value} type="checkbox"/>
                                            <label htmlFor={"option"+index}>{MaysPrimera(item.name.toLowerCase())}</label>
                                        </div>                                       
                                    </div>)}
                                </div>
                                <div className="white-space-24"></div>
                                <div className="search-ok">
                                    <button className="btn btn-filter" type="submit"><FormattedMessage defaultMessage="Filtrar" id="catalogo_filtrar" /></button>
                                </div>
                            </form>
                            <div className="catalog-cards column">
                                <div className="range">
                                    <div className="sort-by align-center">
                                        <p><FormattedMessage defaultMessage="ORDENAR POR: " id="catalogo_filtrar_por" /></p>
                                        <select name="filtrar" id="filtrar" onChange={this.onChangeOrder.bind(this)} value={this.state.optionOrder}>
                                            <FormattedMessage id="orden_popularidad" defaultMessage="Popularidad">
                                                {(message) => <option value="popularity">{message}</option>}
                                            </FormattedMessage>
                                            <FormattedMessage id="orden_mas_nuevo" defaultMessage="Lo más nuevo">
                                                {(message) => <option value="new">{message}</option>}
                                            </FormattedMessage>
                                            <FormattedMessage id="orden_menor_precio" defaultMessage="Menor precio">
                                                {(message) => <option value="low_price">{message}</option>}
                                            </FormattedMessage>
                                            <FormattedMessage id="orden_mayor_precio" defaultMessage="Mayor precio">
                                                {(message) => <option value="high_price">{message}</option>}
                                            </FormattedMessage>
                                        </select>
                                    </div>
                                    <div className="view justify-end align-center">
                                        <p><FormattedMessage defaultMessage="VISTA: " id="catalogo_vista" /></p>
                                        <div className="btn-Listview justify-center">
                                            <a onClick={this.changeView.bind(this, 1)}><i className=" fas fa-th" id="grid" /></a>
                                        </div>
                                        <div className="btn-Listview justify-center">
                                            <a onClick={this.changeView.bind(this, 2)}><i className=" fas fa-list" id="list" /></a>
                                        </div>
                                    </div>
                                </div>
                                {this.state.view === 1 ? <CatalogViewGrid page={this.state.page} products={products} /> : <CatalogViewList page={this.state.page} products={products} />}
                                <div className="white-space-24"></div>
                                {products.length > 9 ?
                                <Paginator
                                    pagina={this.state.page} setpage={this.setpage.bind(this)}
                                    items={Math.ceil(products.length / 9)}
                                /> : null}
                            </div>
                        </div>
                        <div className="white-space-48"></div>
                    </div>
                </div>
                <div className="sl-footer align-center">
                    <Footer />
                </div>
            </div>
        )
    }
}

export default Consumer(injectIntl(Catalog));