import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import socket from '../../core/socket';
import { Consumer } from '../../context';
import moment from 'moment-timezone';

class CardChat extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        socket.on('success', (data) => {
            this.props.context.saveIdChat(data.id);
            this.props.changeWindow(true);
        });

        let id = window.localStorage.getItem('vc_chat');
        if (id) {
            id = JSON.parse(id);
            socket.emit('newConnection', id);
        }
    }

    closeFormChat() {
        document.getElementById("myForm").style.display = "none";
        document.getElementById("buttom-chat").style.display = "flex";
        document.getElementById("chat-scd").style.bottom = "90px";
    }

    startConversation(e) {
        e.preventDefault();
        
        const form = e.target;

        const data = {
            id_socket: socket.id,
            nombre: form.name.value,
            //correo: form.email.value,
            mensaje: form.msg.value,
            fecha: moment().format('YYYY-MM-DD HH:mm:ss')
        };

        socket.emit('chat', data);
    }

    render() {
        return (
            <div className="chat-popup align-center justify-center" id="myForm">
                <div className="popup justify-end">
                    <form onSubmit={this.startConversation.bind(this)} className="form-container column">
                        <div className="fc-title column">
                            <div className="close justify-end">
                                <a onClick={this.closeFormChat.bind(this)}>
                                    <i className="fas fa-times"></i>
                                </a>
                            </div>
                            <div className="head column">
                                <h3>
                                    <FormattedMessage defaultMessage="Chatea con nosotros" id="chat_titulo" values={{
                                        br: <br/>
                                    }}/>
                                </h3>
                            </div>
                        </div>
                        <div className="white-space-16"></div>
                        <div className="fc-inputs column">
                            <label>
                                <FormattedMessage defaultMessage="Nombre" id="chat_nombre" />
                            </label>
                            <div className="white-space-8"></div>
                            <input className="input" type="text" name="name" required/>
                            <div className="white-space-16"></div>
                            {/*<label>
                                <FormattedMessage defaultMessage="Correo" id="chat_correo" />
                            </label>
                            <div className="white-space-8"></div>
                            <input className="input" type="email" name="email" />
                            <div className="white-space-16"></div>*/}
                            <label htmlFor="msg">
                                <FormattedMessage defaultMessage="Mensaje" id="chat_mensaje" />
                            </label>
                            <div className="white-space-8"></div>
                            <textarea className="input" name="msg" required></textarea>
                        </div>
                        <div className="white-space-16"></div>
                        <div className="fc-btn justify-center">
                            <button type="submit" className="btn btn-send">
                                <FormattedMessage defaultMessage="ENVIAR" id="chat_enviar" />
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default Consumer(injectIntl(CardChat));