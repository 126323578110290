import React, { Component } from "react";
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import { FormattedMessage, injectIntl } from 'react-intl';
import playa from '../../src/img/about-us/playa3.jpg';
import Request from '../core/httpClient';
const req = new Request();

class Contact extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: false
		}
	}

    async sendEmail(e) {
        e.preventDefault();
		this.setState({loading: true, message: undefined});
        const form = e.target;
        const name = form.name.value;
        const email = form.email.value;
        const message = form.msg.value;
        const data = { name, email, message };

        const result = await req.post('/contacto/sendemail', data);
        if (result) {
			console.log(result);
            this.setState({message: <FormattedMessage id="contact_result" defaultMessage="Enviado exitosamente, en breve nos comunicamos"></FormattedMessage>})
		}
		this.setState({loading: false});
    }

    render() {
        return(
            <div className="contact column" style={{ backgroundImage: `url(${playa})` }}>
                <Header />
                <div className="column content justify-center align-center">
                    <div className="white-space-32"></div>
                    <div className="content-contact justify-center align-center">
                        <form className="column container" onSubmit={this.sendEmail.bind(this)}>
                            <div className="white-space-24"></div>
                            <div>
                                <h1 style={{ color: '#009688' }}><FormattedMessage defaultMessage="Contacto" id="contact" /></h1>
                            </div>
                            <div className="white-space-16"></div>
                            <div className="row">
                                <div>
                                    <FormattedMessage id="contact_name" defaultMessage="Nombre completo">
                                        {(msg) => (<input type="text" className="input input-left" placeholder={msg} name="name" required />)}
                                    </FormattedMessage>
                                </div>
                                <div>
                                    <FormattedMessage id="contact_correo" defaultMessage="Correo electrónico">
                                        {(msg) => (<input type="email" className="input" placeholder={msg} name="email" required />)}
                                    </FormattedMessage>
                                </div>
                            </div>
                            <div className="white-space-24"></div>
                            <div>
                                <FormattedMessage id="contact_msg" defaultMessage="Asunto...">
                                    {(msg) => (<textarea className="input" name="msg" id="msg" placeholder={msg} cols="30" rows="10" required></textarea>)}
                                </FormattedMessage>
                            </div>
                            <div className="white-space-24"></div>
                            <div className="justify-end">
								<div>
									<h4>{this.state.message || ''}</h4>
								</div>
								{this.state.loading ?
									<i className="fas fa-spinner fa-spin"></i>
									:
                                    <button type="submit" className="btn btn-pink"><FormattedMessage defaultMessage="Enviar" id="contact_send" /></button>
								}
                            </div>
                            <div className="white-space-24"></div>
                            <div className="column justify-end align-center">
                                <div className="align-center justify-start">
                                    <i className="fab fa-whatsapp"></i>&nbsp;<p className="font-small">Tel: 9983298109</p>
                                </div>
								<div className="white-space-8"></div>
								<div className="align-center justify-start">
                                    <i className="fas fa-phone"></i>&nbsp;<p className="font-small"><FormattedMessage defaultMessage="Oficina:" id="contact_office" /> 9988 839013</p>
                                </div>
								<div className="white-space-8"></div>
                                <div className="align-center justify-start">
                                    <i class="far fa-envelope"></i>&nbsp;<p className="font-small"><FormattedMessage defaultMessage="Correo:" id="contact_email" /> reservas@vamoscancun.com</p>
                                </div>
                            </div>
                            <div className="white-space-24"></div>
                        </form>
                    </div>
                    <div className="white-space-32"></div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default injectIntl(Contact);