import React, { Component } from "react";
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import { FormattedMessage, injectIntl } from 'react-intl';

import ImagenPrincipal from '../img/about-us/principal.jpg';
import ImagenClients from '../img/about-us/SN.png';

import ImagenPrimera from '../img/about-us/Primera.jpg';
import ImagenSegunda from '../img/about-us/Segunda.jpg';
import ImagenTercera from '../img/about-us/Tercera.jpg';
import ImagenCuarta from '../img/about-us/Cuarta.jpg';
import ImagenQuinta from '../img/about-us/Quinta.jpg';
import ImagenSexta from '../img/about-us/Sexta.jpg';
import ImagenReserve from '../img/about-us/playa.png';
import ImagenReserve2 from '../img/about-us/reserve.PNG';

import ImagenSecurity1 from '../img/about-us/openpay.png';
import ImagenSecurity2 from '../img/about-us/https.png';
import ImagenSecurity3 from '../img/about-us/security.png';
import ImagenSecurity4 from '../img/about-us/gob.png';

import ImagenUbication from '../img/about-us/ubication.png';
import ImagenPlaya from '../img/about-us/playa2.jpg';
import ImagenCulturaMaya from '../img/CulturaMaya.jpg';
import ImagenMapa from '../img/about-us/mapa.png';

class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: 1
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    onChange(option) {
        this.setState({text: option});
        //document.getElementById(option).style.color = "#FFC800";
    }

    render() {
        return(
            <div className="about-us column">
                <Header />
                <div className="column content justify-center">
                    <div className="row justify-center principal" style={{backgroundImage: `url(${ImagenPrincipal})`}}>
                        <div className="column overlay">
                            <div className="row justify-center principal">
                                <div className="column container align-center justify-center">
                                    {/*<h2 className="tittle-principal text-center"><FormattedMessage defaultMessage="¿Quién es Vamos Cancún?" id="nosotros_quien_es" /></h2>
                                    <div className="white-space-24"></div>*/}
                                    <div className="row info-principal">
                                    <p className="font-medium text-center">
                                        {/*<strong>Vamoscancun.com</strong>*/}
                                        <FormattedMessage defaultMessage="Nosotros." id="nosotros_texto_quien" />
                                    </p>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div> 
                    <div className="row justify-center clients">
                        <div className="column container align-center">
                            <div className="row justify-center">
                                {/*<h1 className="color-green text-center">
                                    <FormattedMessage defaultMessage="Tenemos alrededor de 2 millones de clientes satisfechos " id="nosotros_clientes" />
                                </h1>*/}
                            </div>
                        </div>
                    </div> 
                    <div className="img-responsive four-imgs">
                        <img src={ImagenClients}/>    
                    </div>  
                    <div className="row justify-center offer">
                        <div className="column container align-center">
                            {/*<div className="white-space-24"></div>
                            <div className="row justify-center">
                                <h1 className="color-pink text-center">
                                    <FormattedMessage defaultMessage="¿Qué ofrece Vamos Cancún?" id="nosotros_para_que" />
                                </h1>
                            </div>*/}
                            <div className="white-space-24"></div>
                            <div className="row info-offer">
                                <p className="font-small text-center">
                                    <FormattedMessage defaultMessage="En nuestra página web  " id="nosotros_en_el_sitio" />
                                    <b>vamoscancun.com </b>
                                    <FormattedMessage defaultMessage=" ofrecemos los mejores tours a los mejores precios, llevándote a increíbles lugares de Cancún, la Riviera Maya y la península de Yucatán." id="nosotros_texto_para" />
                                </p>
                            </div>
                            <div className="white-space-48"></div>
                            <div className="first-element row-responsive align-center justify-between">
                                <div className="card-offer-about column">
                                    <img src={ImagenPrimera}></img>
                                    <div className="white-space-8"></div>
                                    <div className="row info-card justify-center">
                                        <h4 className="text-center color-green font-small">
                                            <FormattedMessage defaultMessage="Tour a las zonas arqueológicas de la península" id="nosotros_primera" />
                                        </h4>
                                    </div>      
                                    <div className="white-space-8"></div>                              
                                </div>
                                <div className="card-offer-about column">
                                    <img src={ImagenSegunda}></img>
                                    <div className="white-space-8"></div>
                                    <div className="row info-card justify-center">
                                        <h4 className="text-center color-green font-small">
                                            <FormattedMessage defaultMessage="Viajes en catamarán a Isla Mujeres" id="nosotros_segunda" />
                                        </h4>
                                    </div>      
                                    <div className="white-space-8"></div>                              
                                </div>
                                <div className="card-offer-about column">
                                    <img src={ImagenTercera}></img>
                                    <div className="white-space-8"></div>
                                    <div className="row info-card justify-center">
                                        <h4 className="text-center color-green font-small">
                                            <FormattedMessage defaultMessage="Aventura de nado con delfines" id="nosotros_tercera" />
                                        </h4>
                                    </div>      
                                    <div className="white-space-8"></div>                              
                                </div>
                                <div className="card-offer-about column">
                                    <img src={ImagenCuarta}></img>
                                    <div className="white-space-8"></div>
                                    <div className="row info-card justify-center">
                                        <h4 className="text-center color-green font-small">
                                            <FormattedMessage defaultMessage="Cenando con los Piratas" id="nosotros_cuarta" />
                                        </h4>
                                    </div>
                                    <div className="white-space-8"></div>
                                </div>
                                <div className="card-offer-about column">
                                    <img src={ImagenQuinta}></img>
                                    <div className="white-space-8"></div>
                                    <div className="row info-card justify-center">
                                        <h4 className="text-center color-green font-small">
                                            <FormattedMessage defaultMessage="Aventuras de snorkel" id="nosotros_quinta" />
                                        </h4>
                                    </div>
                                    <div className="white-space-8"></div>
                                </div>
                                <div className="card-offer-about column">
                                    <img src={ImagenSexta}></img>
                                    <div className="white-space-8"></div>
                                    <div className="row info-card justify-center">
                                        <h4 className="text-center color-green font-small">
                                            <FormattedMessage defaultMessage="¡Y muchos más! Echa un vistazo a nuestro catálogo" id="nosotros_sexta" />
                                        </h4>
                                    </div>
                                    <div className="white-space-8"></div>
                                </div>
                            </div>
                            {/*<div className="second-element row-responsive align-center justify-between">
                                <div className="card-offer-about column">
                                    <img src={ImagenXcaret}></img>
                                    <div className="white-space-8"></div>
                                    <div className="row info-card justify-center">
                                        <h4 className="text-center color-green font-small">
                                            <FormattedMessage defaultMessage="Una visita a los parques XCARET" id="nosotros_xcaret" />
                                        </h4>
                                    </div>      
                                    <div className="white-space-8"></div>                              
                                </div>
                                <div className="card-offer-about column">
                                    <img src={ImagenCozumel}></img>
                                    <div className="white-space-8"></div>
                                    <div className="row info-card justify-center">
                                        <h4 className="text-center color-green font-small">
                                            <FormattedMessage defaultMessage="Un fantástico tour a Cozumel" id="nosotros_cozumel" />
                                        </h4>
                                    </div>      
                                    <div className="white-space-8"></div>                              
                                </div>
                                <div className="card-offer-about column">
                                    <img src={ImagenLogo}></img>
                                    <div className="white-space-8"></div>
                                    <div className="row info-card justify-center">
                                        <h4 className="text-center color-green font-small">
                                            <FormattedMessage defaultMessage="Y muchos más! Revisa nuestro catálogo" id="nosotros_catalogo" />
                                        </h4>
                                    </div>      
                                    <div className="white-space-8"></div>                              
                                </div>
                            </div>*/}
                            <div className="white-space-32"></div>
                        </div>                       
                    </div>

                    <div className="culture column align-center">
                        <div className="container column justify-center align-center">
                            <div className="first-text">
                                <p className="text-center">
                                    <FormattedMessage defaultMessage="La admiración y el cariño por la riqueza de esta cultura nos impulsa a ofrecer a nuestros clientes productos 100% artesanales y así ayudar a promover la economía de los artesanos de la región quienes con su talento plasman en cada pieza su herencia cultural, convirtiéndola en verdaderas obras de arte." id="nosotros_maya_first" />
                                </p>
                            </div>
                            <div className="white-space-32"></div>
                            <div className="img-culture justify-center">
                                <img src={ImagenCulturaMaya} />
                            </div>
                            <div className="white-space-32"></div>
                            <div className="column second-text">
                                <p className="text-center">
                                    <FormattedMessage defaultMessage="Como emprendedores no solo hemos buscado generar ganancia sino resaltar la grandeza de esta cultura ancestral, así como beneficiar cada vez a más productores y artesanos que hacen de su trabajo una pasión, ayudándolos a preservar su identidad." id="nosotros_maya_second" />
                                </p>
                                <p className="text-center">
                                    <FormattedMessage defaultMessage="Trabajamos de la mano con hombres y mujeres cuyo principal objetivo es salvaguardar las técnicas artesanales transmitidas de generación en generación desde la época prehispánica, donde impera la calidad de un trabajo hecho a mano y con la seguridad de quien adquiere una de estas piezas se lleva a su hogar el alma de una cultura que se mantiene viva." id="nosotros_maya_third" />
                                </p>
                            </div>
                        </div>
                        <div className="white-space-48"></div>
                    </div> 
                   
                    <div className="row justify-center reserve" style={{backgroundImage: `url(${ImagenReserve})`}}>
                        <div className="column overlay align-center justify-center">
                            <div className="row-responsive justify-center align-center container">
                                <div className="column align-center justify-center">
                                    <div className="row info-principal">
                                    <p className="font-small text-center">
                                        <FormattedMessage defaultMessage="Reservar en " id="nosotros_reserva_uno" />
                                        <b>vamoscancun.com</b> 
                                        <FormattedMessage defaultMessage=" y deja que te brindemos el mejor transporte, los mejores guías turísticos y el servicio que te mereces. Vamos!" id="nosotros_reserva_dos" />
                                    </p>
                                    </div>
                                </div>    
                                <div className="imagen column">
                                    <div className="responsive-img row justify-center">
                                        <img src={ImagenReserve2} className="cover"></img>
                                    </div>
                                </div>                            
                            </div>                           
                        </div>                        
                    </div> 
                    <div className="row justify-center security">
                        <div className="column container align-center">
                            {/*<div className="white-space-24"></div>
                            <div className="row justify-center">
                                <h1 className="color-pink text-center">
                                    <FormattedMessage defaultMessage="¿Qué tan seguro es el sitio Web vamoscancun.com?" id="nosotros_seguridad" />
                                </h1>
                            </div>*/}
                            <div className="white-space-24"></div>
                            <div className="row info-offer">
                                <p className="font-small text-center">
                                    <FormattedMessage defaultMessage="Nuestra web cuenta con un " id="nosotros_texto_seguridad_uno" />
                                    <b><FormattedMessage defaultMessage="certificado de seguridad" id="nosotros_texto_seguridad_dos" /></b> 
                                    <FormattedMessage defaultMessage=" y todos tus datos están protegidos según la legislación mexicana de protección de datos. Los pagos se procesan en " id="nosotros_texto_seguridad_tres" /> 
                                    <b>OPENPAY</b> 
                                    <FormattedMessage defaultMessage=" (plataforma bbva Bancomer), mismo que garantiza que los datos de tu tarjeta de crédito pasan por un proceso de encriptación de 256 bits." id="nosotros_texto_seguridad_cuatro" />
                                </p>
                            </div>
                            <div className="white-space-48"></div>
                            <div className="row">
                                <div className="imagen column">
                                    <div className="responsive-img row justify-center">
                                        <img src={ImagenSecurity1} className="cover img-openpay"></img>
                                    </div>
                                </div>  
                                <div className="imagen column">
                                    <div className="responsive-img row justify-center">
                                        <img src={ImagenSecurity2} className="cover img-https"></img>
                                    </div>
                                </div> 
                                <div className="imagen column">
                                    <div className="responsive-img row justify-center">
                                        <img src={ImagenSecurity3} className="cover img-security"></img>
                                    </div>
                                </div> 
                                <div className="imagen column justify-center">
                                    <div className="responsive-img row justify-center">
                                        <img src={ImagenSecurity4} className="cover img-gob"></img>
                                    </div>
                                </div> 
                            </div>
                            <div className="white-space-48"></div>
                            <div className="row info-offer">
                                <p className="font-small text-center">
                                    <FormattedMessage defaultMessage="De igual modo, se certifica que es " id="nosotros_certifica_uno" />
                                    <b>Cancun Discount Tour S.A. de C.V.</b> 
                                    <FormattedMessage defaultMessage=" la propietaria del dominio vamoscancun.com y que se trata de una empresa que cumple todos los requisitos legales." id="nosotros_certifica_dos" />
                                </p>
                            </div>
                        </div>                       
                    </div> 
                    <div className="white-space-32"></div>
                    <div className="row justify-center ubication" style={{backgroundImage: `url(${ImagenPlaya})`}}>
                        <div className="column overlay align-center justify-center">
                            <div className="row row-reverse justify-center align-center container">
                                <div className="column  align-center justify-center">
                                {/*<h4 className="tittle-principal text-center"><FormattedMessage defaultMessage="¿Dónde se ubican físicamente las oficinas y el personal de vamoscancun.com?" id="nosotros_donde" /></h4>*/}
                                    <div className="white-space-24"></div>
                                    <div className="row info-principal">
                                    <p className="font-small text-center">
                                        <b>Cancun Discount Tour S.A. de C.V.</b> 
                                        <FormattedMessage defaultMessage=" y su sitio " id="nosotros_sitio" />
                                        <b>vamoscancun.com</b> 
                                        <FormattedMessage defaultMessage=" poseen oficinas centrales en Cancún. Oficinas centrales de vamoscancun.com Avenida Bonampak lote 1, SM 3, MZ 13, Edificio B Towers, Torre D, Local 201 Cancún, Quintana Roo, México." id="nosotros_texto_donde" />
                                    </p>
                                    </div>
                                </div>    
                                <div className="imagen column">
                                    <div className="responsive-img row justify-center">
                                        <img src={ImagenMapa} className="cover"></img>
                                    </div>
                                </div>                            
                            </div>                           
                        </div>                        
                    </div> 
                </div>
                <div className="white-space-24"></div>
                <Footer />
            </div>
        )
    }
}

export default injectIntl(AboutUs);