import React, { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import CardReservation from '../cards/card-reservation';
import PayData from './pay-data';
import NumberFormat from "react-number-format";
import { Consumer } from '../../context';

class Payment extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }
    
    render() {
        let cart = [];
        if (this.props.cart) {
            cart = this.props.cart;
        }

        let tc = 1;
        const exrate = window.localStorage.getItem('exrate');
        if(exrate === 'MXN') {
            tc = this.props.context.tc;
        } else if(exrate === 'USD') {
            tc = 1;
        }

        return (
            <div className="payment justify-center">
                <div className="container">
                    <div className="payment-sections row-responsive">
                        <div className="payment-left column">
                            <div className="payment-title justify-center">
                                <h3><FormattedMessage defaultMessage="PAGO" id="carrito_pago" /></h3>
                            </div>
                            <div className="white-space-24"></div>
                            <div className="pay column">
                                <PayData />
                            </div>
                        </div>
                        <div className="payment-right justify-center">
                            <div className="payment-detail column">
                                <div className="payment-detail-title justify-center">
                                    <h3><FormattedMessage defaultMessage="DETALLES DE RESERVACIÓN" id="carrito_resumen" /></h3>
                                </div>
                                <div className="payment-detail-cart column">
                                    {cart.map((item, index) =>
                                        <CardReservation key={index} item={item} /> )}
                                </div>
                                <div className="white-space-24"></div>
                                <div className="payment-detail-total column">
                                    <div className="row">
                                        <div className="total-title justify-end align-center">
                                            <h4>TOTAL: &nbsp;</h4>
                                        </div>
                                        <div className="total-price justify-center align-center">   
                                            <p className="exrate">{exrate}</p>
                                            <h3>
                                                <NumberFormat
                                                    value={((cart.reduce((sum, product) => sum + product.total, 0)) * tc).toFixed(2)}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                    prefix={"$"}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true} />
                                            </h3>
                                        </div>
                                    </div>
                                    {this.props.context.user.auth && cart.length > 1 && <div className="justify-end column">
                                        <div className="white-space-8"></div>
                                        <p className="text-right color-red">Se aplicó un descuento adicional del {cart.length > 2 ? '10%' : cart.length > 1 ? '5%' : null}</p>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Consumer(injectIntl(Payment));