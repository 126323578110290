import React, { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import Header from '../components/header/header';
import CardCart from '../components/cards/card-cart';
import CardItemCart from '../components/cards/card-itemcart';
import Reservation from '../components/cart/reservation';
import Payment from '../components/cart/payment';
import Footer from '../components/footer/footer';
import { Consumer } from "../context";
import { URL_API } from '../core/urlsApi';
import Request from '../core/httpClient';
import NumberFormat from "react-number-format";
import swal from 'sweetalert';
import { isMobile } from "react-device-detect";
import moment from 'moment-timezone';

import Imagen from '../img/xenotes-02.jpg';

const req = new Request();

class Cart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            carrito: [],
            hoteles: [],
            zonas: [],
            loading: false,
            exrate: '',
            preReserva: true
            /*reservationBox: false*/
        }
    }

    componentDidMount() {
        this.dateChanged();
        this.load();
    }

    async load() {
        const zona = await req.post('/admin/hoteles/zonas');
        if (zona) {
            if (zona.zonas) {
                this.setState({ zonas: zona.zonas });
            }
        }

        const hotel = await req.post('/hoteles/get');
        if (hotel) {
            if (hotel.hoteles) {
                const hoteles = hotel.hoteles.sort((a,b) => {
                    if(a.nombre < b.nombre) {
                        return -1
                    }
                    if(a.nombre > b.nombre) {
                        return 1
                    } return 0;
                })
                this.setState({ hoteles });
            }
        }

        let carrito = window.localStorage.getItem('cart');
        if (carrito) {
            carrito = JSON.parse(carrito);
            this.setState({ carrito });
        }

        let user = window.localStorage.getItem('tousr');
        if (user) {
            this.setState({ user: true });
        } else {
            this.setState({ user: false });
        }

        let exrate = window.localStorage.getItem('exrate');
        if (exrate) {
            //exrate = JSON.parse(exrate);
            this.setState({ exrate });
        }
    }

    dateChanged() {
        let carrito = [], fecha_espera = '', fecha_cercana = '';
        const cart = window.localStorage.getItem('cart');
        if (cart) {
            carrito = JSON.parse(cart);

            if (carrito.length !== 0) {
                fecha_cercana = carrito.sort(function (a, b) {
                    var dateA = new Date(a.fecha), dateB = new Date(b.fecha);
                    return dateA - dateB;
                });

                fecha_espera = moment(fecha_cercana[0].fecha).add(-6, 'days').toDate();
                //this.setState({ preReserva: true });
                if (fecha_espera < moment().toDate()) {
                    fecha_espera = moment(fecha_cercana[0].fecha).add(-2, 'days').toDate();

                    /*console.log("Fecha espera2: ", fecha_espera);

                    if (fecha_espera <= moment().toDate()) {
                        console.log("Holi");
                        this.setState({ preReserva: false });
                    } else {
                        console.log("Holi2");
                        this.setState({ preReserva: true });
                    }*/
                }
            }
        }

        this.setState({ carrito, fecha_espera });
    }

    validateDecimal(event) {
        event.preventDefault();

        this.changeStep(2);
    }

    changeStep(type) {
        let step = document.getElementById("step"+type);
        if(step.classList.contains("step-active")) {
            let prevStep = document.getElementById("step"+(type+1));
            if (prevStep) {
                prevStep.classList.remove("step-active");
                prevStep.classList.add("step-circle");
            }
        } 
        step.classList.remove("step-circle");
        step.classList.add("step-active");
        this.setState({ step: type, reservationBox: false });
    }

    async travelerInformation(event) {
        event.preventDefault();

        const name = event.target.name.value;
        const lastName = event.target.last_name.value;
        const email = event.target.email.value;
        const country = event.target.country.value;
        let hotel = event.target.hotel.value;
        const telephone = event.target.telephone.value;
        const comment = event.target.comment.value;

        if (hotel === "otro") {
            hotel = event.target.other.value;
        }
        
        let cliente = {name: name, lastName: lastName, email: email, country: country, telephone: telephone, comment: comment};
        cliente = JSON.stringify(cliente);

        let user = window.localStorage.getItem("tousr");
        let id_agencia = 0;
        console.log('user', user);
        if (user) {
            user = JSON.parse(user);

            const resUser = await req.post('/usuarios/tokuser', { id: user.id });
            if (resUser) {
                id_agencia = resUser.data.agencia;
            }
        } else {
            id_agencia = 0;
        }

        let folio = '', saveData = '', descuento_adicional = 0, total = 0, totalTC = 0;

        let cart = window.localStorage.getItem('cart');
        if (cart) {
            cart = JSON.parse(cart);
            //06/03/2020
            total = cart.reduce((sum, product) => sum + product.total, 0);
            totalTC = (total * this.props.context.tc).toFixed(2);

            if (id_agencia !== 0) {
                if (cart.length > 2) {
                    descuento_adicional = 10;
                }

                if (cart.length === 2) {
                    descuento_adicional = 5;
                }
            // console.log(cart);
            }
        }

        const moneda = window.localStorage.getItem('exrate');

        //const data = { cart: cart, id_status: 0, cliente: cliente, estado: 'RESERVADO', hotel: hotel, moneda: moneda, tc: this.props.context.tc/*, total*/ };

        /*const data = { id_producto: 2, adultos: 1, menores: 1, precio_adulto: 250.00, precio_menor: 50.00, descuento: 100.00, id_status: 1, cliente: cliente, estado: 'RESERVADOS', total: 150.00 };*/

        //06/03/2020-pedidos a purchase
		let save = '', data = '';
		let locale = window.localStorage.getItem('cto_locale');
		const exrate = window.localStorage.getItem('exrate');
		if (!locale) {
			locale = 'es';
		}
        if (this.state.opt === 'pagar') {
            data = { cart: cart, id_status: 0, cliente, estado: 'EN ESPERA', hotel, moneda: moneda, tc: this.props.context.tc, id_agencia, descuento_adicional};
            save = await req.post('/pedidos/save', data);

            if (save) {
                if (save.waiting) {
                    folio = save.folio;
                    //alert('Reservado' + save.folio);

                    saveData = { folio: folio, name: name, lastName: lastName, email: email, country: country, telephone: telephone, comment: comment, total: totalTC, monto: total, cantidad: cart.length, locale: locale, exrate: exrate, descuento_adicional };

                    this.props.context.saveReservation(saveData);

                    this.changeStep(3);
                } else {
                    swal(save.message);
                }
            }
        } else {
			this.setState({loadingPreSale: true});
            data = { cart: cart, id_status: 0, cliente: cliente, estado: 'RESERVADO', hotel: hotel, moneda: moneda, tc: this.props.context.tc, total: totalTC, monto: total, cantidad: cart.length, locale: locale, due_date: moment(this.state.fecha_espera).utc().toDate(), id_agencia, descuento_adicional };
			console.log('DATA PRESALE', data);
			save = await req.post('/purchase/save', data);
			this.setState({loadingPreSale: false});
            if (save) {
                console.log(save);
                if (save.reservation) {
                    window.location = '/carrito/reservacion';
                    //swal("¡Gracias por reservar en Vamos Cancún! El formulario de pago ha sido enviado a su correo.");
                } else {
                    swal(save.message);
                }
            }
        }
    }

    async openAd(option) {
        this.dateChanged();
        this.setState({ reservationBox: option});
    }

    async makeReservation(opt) {
        this.setState({ opt });
    }

    async payment(event) {
        event.preventDefault();
        this.setState({ loading: true });

        /*const cardName = event.target.holder_name.value;
        console.log("Nombre: ", cardName);*/

        this.startOpenPay();

        const form = event.target;
        let cont = true;

        if (!window.OpenPay.card.validateCardNumber(form.card_number.value)) {
            document.getElementById('card_number').style.borderColor = "red";
            cont = false;
            this.setState({ loading: false });
        }
        if (!window.OpenPay.card.validateCVC(form.cvv.value)) {
            document.getElementById('cvv').style.borderColor = "red";
            cont = false;
            this.setState({ loading: false });
        }
        if (!window.OpenPay.card.validateExpiry(form.expiration_month.value, form.expiration_year.value)) {
            document.getElementById('expiration_month').style.borderColor = "red";
            document.getElementById('expiration_year').style.borderColor = "red";
            cont = false;
            this.setState({ loading: false });
        }
        window.OpenPay.card.cardType(form.card_number.value);
        if (cont) {
            document.getElementById('card_number').style.borderColor = "black";
            document.getElementById('cvv').style.borderColor = "black";
            document.getElementById('expiration_month').style.borderColor = "black";
            document.getElementById('expiration_year').style.borderColor = "black";

            window.OpenPay.token.extractFormAndCreate(
                form,
                this.SUCCESS_CALLBACK.bind(this),
                this.ERROR_CALLBACK.bind(this)
            );
        }
    }

    startOpenPay() {
		// Cambiar para TEST
		if(process.env.NODE_ENV !== 'production') {
			window.OpenPay.setId('mretfnohj9dooh8p7ouo');
			window.OpenPay.setApiKey('pk_a247094f952d4f0a9a0a347f123acfbf');
            window.OpenPay.setSandboxMode(true);
		} else {
            window.OpenPay.setId('mvbrq2vruwarddghwiur');
            window.OpenPay.setApiKey('pk_2785cf5257d24e90912934e056e2f1f2');
            /*window.OpenPay.setId('m8hh8laxhehfvsxwjnyd');
			window.OpenPay.setApiKey('pk_4ef45482ca33465e902a901167e0bb17');
            window.OpenPay.setSandboxMode(true);*/
		}
        const sesion = window.OpenPay.deviceData.setup("processCard", "deviceIdHiddenFieldName");

        this.setState({
            sessionID: sesion
        });
    }

    SUCCESS_CALLBACK(event) {
        const saveData = this.props.context.reservation;
        console.log("saveData: ", saveData);

        const dataPayment = {
            token_id: event.data.id,
            device_session_id: this.state.sessionID,
            name: saveData.name,
            last_name: saveData.lastName,
            telephone: saveData.telephone,
            email: saveData.email,
            folio: saveData.folio,
            amount: saveData.total,
            cantidad: saveData.cantidad,
            locale: saveData.locale,
			exrate: saveData.exrate,
			moneda: window.localStorage.getItem('exrate'),
            tc: this.props.context.tc,
            monto: saveData.monto
        };
        console.log("dataPayment: ", dataPayment);

        this.sendPay(dataPayment);
    }

    ERROR_CALLBACK(event) {
        if (event.data.description === "cvv2 length must be 4 digits") {
            swal("Error. CVV debe contener 4 dígitos.");
        }
        else if (event.data.description === "cvv2 length must be 3 digits") {
            swal("Error. CVV debe contener 3 dígitos.");
        } else {
            swal(event.data.description);
        }
        this.setState({ loading: false });
    }

    async sendPay(data) {
        this.setState({ loading: true });
        console.log("Pago", data);
        const req = new Request();
        const send = await req.post('/purchase/pay', data);
        if (send) {
            console.log(send);
            if (send.redirect) {
                window.location = send.redirect;
            } 
            if (send.error) {
                swal("Error " + send.error + ". Intente de nuevo.");
            }
            this.setState({ message: send.message, loading: false });
        }
    }

    keepShopping() {
        this.props.history.push('./');
    } 

    updateCart(cart) {
        console.log(cart);
        this.setState({ carrito: cart });
    }

    removeFCart(product, index) {
        const carrito = this.props.context.removeFromCart(product, index);
        this.dateChanged();

        this.setState({ carrito });
    }

    render() {
        let cart = [];
        
        if (this.state.carrito) {
            cart = this.state.carrito;
            console.log("Cart", cart);
        }

        let tc = 1, exrate = '';
        console.log("Context: ", this.props.context);
        console.log("exrate", this.state.exrate);
        if (this.state.exrate) {
            exrate = this.state.exrate;

            if (this.state.exrate === 'MXN') {
                tc = this.props.context.tc;
                console.log(tc);
            } else if (this.state.exrate === 'USD') {
                tc = 1;
            }
        }

        return (
            <div className="cart column align-center">
                <Header />
                <div className="white-space-32"></div>
                <div className="section-cart column align-center justify-center">
                    <div className="row taps justify-between">
                        <div className="steps auto align-center">
                            <div className="step-active auto" id="step1">
                                <h5 className="weight-bold color-white">1</h5>
                            </div>
                            <h2 className="font-small color-green">
                                <FormattedMessage defaultMessage="Revisar reservación" id="carrito_revisa_reservacion" />
                            </h2>
                        </div>
                        <div className="steps auto align-center" >
                            <div className="step-circle auto" id="step2">
                                <h5 className="weight-bold color-white">2</h5>
                            </div>
                            <h2 className="font-small color-green">
                                <FormattedMessage defaultMessage="Realiza tu reservación" id="carrito_hacer_reservacion" />
                            </h2>
                        </div>
                        <div className="steps auto align-center" >
                            <div className="step-circle auto" id="step3">
                                <h5 className="weight-bold color-white">3</h5>
                            </div>
                            <h2 className="font-small color-green">
                                <FormattedMessage defaultMessage="Confirmación" id="carrito_confirmacion" />
                            </h2>
                        </div>
                    </div>
                    <div className="titulo justify-center">
                        {this.state.step === 1 ? 
                        <h4>Revisar reservación</h4> : this.state.step === 2 ?
                        <h4>Realizar reservación</h4> : <h4>Confirmación</h4> }
                    </div>
                    <div className="white-space-48"></div>
                    {
                        this.state.step === 1 ?
                            <form className="cart-order column container justify-center align-center" onSubmit={this.validateDecimal.bind(this)}>
                            {/* <div className="carrito row-responsive align-center">
                                    <div className="column align-center">
                                        {this.state.carrito.length !== 0 ? this.state.carrito.map((item, index) =>
                                            <CardCart updateCart={this.updateCart.bind(this)} key={index} cart={item} position={index} removeFCart={this.removeFCart.bind(this)} />)
                                            : <h2>
                                                <FormattedMessage defaultMessage="Tu carrito está vacío." id="carrito_vacio" />
                                            </h2>}
                                    </div>
                                        </div>*/}
                                
                                    {this.state.carrito.length !== 0 ?
                                    <div className="column container shopping-cart">
                                        <div className="column">
                                            <div className="row header-cart">
                                                <div className="column tour-column  align-center">
                                                    <p className="font-regular  weight-semi tittle">
                                                        <FormattedMessage defaultMessage="Tour" id="Tour" />
                                                    </p>
                                                </div>
                                                <div className="column">
                                                    <div className="row">
                                                    <div className="column align-center">
                                                    <p className="font-regular weight-semi tittle">
                                                    
                                                    </p>
                                                    </div>
                                                    <div className="column align-center">
                                                        <p className="font-regular weight-semi tittle">
                                                            <FormattedMessage defaultMessage="Precio" id="Precio" />
                                                        </p>
                                                    </div>
                                                    <div className="column align-center">
                                                        <p className="font-regular weight-semi tittle">
                                                            <FormattedMessage defaultMessage="Cantidad" id="Cantidad" />
                                                        </p>
                                                    </div>
                                                    <div className="column align-center">
                                                        <p className="font-regular weight-semi tittle">
                                                            <FormattedMessage defaultMessage="Subtotal" id="Subtotal" />
                                                        </p>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="white-space-16"></div>
                                        {this.state.carrito.map((item, index) =>
                                        <CardItemCart updateCart={this.updateCart.bind(this)} key={index} cart={item} position={index} removeFCart={this.removeFCart.bind(this)} descuentoAdicional={this.state.carrito.length > 2 ? 2 : this.state.carrito.length > 1 ? 1 : 0} /> )}
                                    </div>
                                    :
                                    <React.Fragment>
                                        <div className="column">
                                            <h2 className="text-center">
                                                <FormattedMessage defaultMessage="Tu carrito está vacío." id="carrito_vacio" />
                                            </h2>
                                            <div className="white-space-16"></div>
                                        </div>
                                    </React.Fragment> }
                
                                {/*<div className="row total-column justify-end">
                                    <div className="label align-center justify-end">
                                        <h2 className="label weight-semi"> Total :</h2>
                                    </div>
                                    <div className="column final-price justify-center"> 
                                        <h1 className=" color-green">$1650.00 usd</h1>
                                    </div>
                                </div>*/}
                                {this.state.carrito.length !== 0 ?
                                    <div className="check-reservation column container justify-center">
                                        {this.state.user && this.state.carrito.length > 1 && <div className="justify-end column">
                                            <p className="text-right color-red">Se aplicó un descuento adicional del {this.state.carrito.length > 2 ? '10%' : this.state.carrito.length > 1 ? '5%' : null}</p>
                                            <div className="white-space-8"></div>
                                        </div>}
                                        <div className="row-responsive">
                                            <div className="check-reservation-buttons justify-between">
                                                <button className="btn btn-back" type="button" onClick={this.keepShopping.bind(this)}>
                                                    <FormattedMessage defaultMessage="Seguir comprando" id="carrito_seguir_comprando" />
                                                </button>
                                                <button className="btn btn-cart" type="submit">
                                                    <FormattedMessage defaultMessage="Continuar" id="carrito_continuar" />
                                                </button>
                                            </div>
                                            {isMobile ?
                                                <div className="check-reservation-total column justify-center">
                                                    <div className="row justify-center">
                                                        <h4 className="color-red">Precios en {exrate}</h4>
                                                    </div>
                                                    <div className="row">
                                                        <div className="label align-center justify-center">
                                                            <h2 className="label weight-semi">Total:</h2>
                                                        </div>
                                                        <div className="row final-price align-center justify-center">
                                                            <NumberFormat
                                                                className="color-green font-huge"
                                                                value={((this.state.carrito.reduce((sum, product) => sum + product.total, 0)) * tc).toFixed(2)}
                                                                displayType={"text"}
                                                                thousandSeparator={true}
                                                                prefix={"$"}
                                                                decimalScale={2}
                                                                fixedDecimalScale={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="check-reservation-total justify-center">
                                                    <div className="label align-center justify-end">
                                                        <h2 className="label weight-semi"> Total:</h2>
                                                    </div>
                                                    <div className="row final-price align-center justify-center">
                                                        <p className="exrate">{exrate}</p>
                                                        <NumberFormat
                                                            className="color-green font-huge"
                                                            value={Math.ceil((this.state.carrito.reduce((sum, product) => sum + product.total, 0)) * tc).toFixed(2)}
                                                            displayType={"text"}
                                                            thousandSeparator={true}
                                                            prefix={"$"}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true} />
                                                    </div>
                                                </div>
                                            }
                                        </div> 
                                    </div>
                                :
                                    <div className="check-reservation row-responsive container justify-center">
                                        <div className="check-reservation-buttons justify-center">
                                            <button className="btn btn-back" onClick={this.keepShopping.bind(this)}>
                                                <FormattedMessage defaultMessage="Seguir comprando" id="carrito_seguir_comprando" />
                                            </button>
                                        </div>
                                    </div>}
                            </form>
                        :
                        this.state.step === 2 ?
                            <form className="justify-center column align-center" onSubmit={this.travelerInformation.bind(this)}>
                                <div className="column container justify-center align-center">
                                    <Reservation cart={cart} zonas={this.state.zonas} hoteles={this.state.hoteles} />
                                    <div className="make-reservation row container justify-center">
                                        <div className="back justify-start">
                                            <button className="btn btn-back" type="button" onClick={this.changeStep.bind(this, 1)}>
                                                <FormattedMessage defaultMessage="Regresar" id="carrito_regresar" />
                                            </button>
                                        </div>
                                        <div className="continue justify-end">
                                            <a className="btn btn-pay text-center" href="#reser" onClick={this.openAd.bind(this, !this.state.reservationBox)}>
                                                {/*Pagar a Reservar*/}
                                                <FormattedMessage defaultMessage="Pre Reserva" id="carrito_pre_reserva" />
											</a> &nbsp; 
                                            <button className="btn btn-cart" type="submit" onClick={this.makeReservation.bind(this, 'pagar')}>
                                                <FormattedMessage defaultMessage="Pagar" id="carrito_pagar" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {this.state.reservationBox && this.state.step === 2 ?
                                    <div className="reser justify-center" id="reser">
                                        <div className="container justify-center">
                                            <div className="container reservation-box align-center">
                                                <div className="reservation-text column">
                                                    <p><FormattedMessage defaultMessage={`Puedes reservar tantos tours como quieras, recibirás la confirmación en tu email, asegúrate de realizar tu pago antes del ${moment(this.state.fecha_espera).format('DD/MM/YYYY')} para conservar precios y disponibilidad ¡Gracias por tu compra!`} id="carrito_reservation" /></p>
                                                </div>
												{this.state.loadingPreSale? 
												<div className="row justify-center align-center">
													<i className="fas fa-spinner fa-spin"></i>
												</div>
												:
                                                <div className="reservation-button justify-center">
                                                    <button type="submit" className="btn btn-pay" onClick={this.makeReservation.bind(this, 'reserva')}>Aceptar</button>
                                                </div>
												}
                                            </div>
                                        </div>
                                    </div> : null}
                            </form>
                        :
                        this.state.step === 3 ?
                            <form className="justify-center" id="processCard" name="processCard" onSubmit={this.payment.bind(this)}>
                                <div className="column container justify-center align-center">
                                    <Payment cart={cart} />
                                    <div className="confirmation row container justify-center">
                                        <div className="back justify-start">
                                            <button className="btn btn-back" onClick={this.changeStep.bind(this, 2)}>
                                                <FormattedMessage defaultMessage="Regresar" id="carrito_regresar" />
                                            </button>
                                        </div>
                                        <div className="continue justify-end">
                                            <button className="btn btn-pay" type="submit">
                                                <FormattedMessage defaultMessage="Pagar" id="pago_boton" />
                                                &nbsp;
                                                {this.state.loading ? 
                                                <i className="fas fa-spin fa-spinner"></i> : null}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        : null
                    }
                </div>
                <div className="white-space-24"></div>
                <div className="sl-footer align-center">
                    <Footer />
                </div>
            </div>
        )
    }
}

export default Consumer(injectIntl(Cart));