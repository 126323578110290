import React, { Component } from 'react';
import Imagen from '../../img/xplor-fuego.jpg';
import { FormattedMessage, injectIntl } from 'react-intl';

class ModalBanner extends Component {
    closeModal() {
        document.getElementById("modal-banner").classList.add("hidden");
        
        window.localStorage.setItem('banner', this.props.productsB.idBProducto);
    }

    redirectBanner(linea, categoria) {
        window.localStorage.setItem('banner', this.props.productsB.idBProducto);
        //this.props.dispatch({ type: 'REDIRECT_BANNER', data: { linea, categoria } });
        window.location = '/catalogo/cat/' + this.props.productsB.categoria;
    }

    render() {
        let product = [], descriptionArray = [], description, image = '';
        if (this.props.productsB) {
            product = this.props.productsB;

            if (product.descripcion) {
                descriptionArray = JSON.parse(product.descripcion);
                description = descriptionArray[this.props.locale];
            }

            if (product.imagen) {
                image = JSON.parse(product.imagen);
                image = image[this.props.locale];
            }
        }

        return (
            <div className="flex modal justify-center hidden" id="modal-banner">
                <div className="container banner-content modal-content column">
                    <div className="modal-body-banner">
                        <div className="info-product column justify-center align-center" style={{ backgroundImage: `url(${product ? image !== '' ? image : null : ''})` }}>
                            {/*<img className="img-banner img-responsive" src={product ? product.imagen : ''} />*/}
                            <div className="banner-b column container align-center justify-center">
                                <div className="text-banner justify-center">
                                    <h1 className="text-center" style={{ color: `${product ? product.colorText : 'white'}` }}>
                                        {description ? description : ''}
                                    </h1>
                                </div>
								{product.categoria > 0 &&
                                <div className="justify-center">
                                    <button className="btn btn-buy btn-banner" onClick={this.redirectBanner.bind(this, product.categoria)}>
                                        <FormattedMessage
                                            id="banner-ver"
                                            defaultMessage="VER"
                                        />
                                    </button>
                                </div>
								}
                            </div>
                        </div>
						<div className="close-banner justify-center">
							<a onClick={this.closeModal.bind(this)}><i class="fas fa-times"></i></a>
							{/*<i class="fas fa-times-circle"></i>*/}
						</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(ModalBanner);