import React, { Component } from "react";
import OwlCarousel from 'react-owl-carousel2';
//import 'react-owl-carousel2/lib/styles.css';
import Imagen1 from '../img/slider/1.jpg';
import Imagen2 from '../img/slider/2.jpg';
import Imagen3 from '../img/slider/3.jpg';
import Imagen4 from '../img/slider/4.jpg';
import Imagen5 from '../img/slider/5.jpg';
import Imagen6 from '../img/slider/6.jpg';
import Imagen7 from '../img/slider/7.jpg';
import Imagen8 from '../img/slider/8.jpg';
import Imagen9 from '../img/slider/9.jpg';
import Imagen10 from '../img/slider/10.jpg';
import Imagen11 from '../img/slider/11.jpg';
import Imagen12 from '../img/slider/12.jpg';
import Imagen13 from '../img/slider/13.jpg';
import Imagen14 from '../img/slider/14.jpg';
import Imagen15 from '../img/slider/15.jpg';
import Imagen from '../img/1-tour.jpg';

class SliderM extends Component {
    render() {
        const options = {
            items: 1,
            /*nav: true,
            navText: [`<i class="fa fa-angle-left fa-5x"></i>`, `<i class="fa fa-angle-right fa-5x"></i>`],*/
            rewind: true,
            autoplay: true,
            loop: true,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                    nav: false
                },
                600: {
                    items: 1,
                    nav: false
                },
                1000: {
                    items: 1,
                    nav: false
                },
                1300: {
                    items: 1,
                    nav: false
                }
            }
        };

        return (
            <OwlCarousel options={options} >
                {this.props.page === "home" ?
                <React.Fragment>
                    <div><img src={Imagen1} alt="Mirror Edge" /></div>
                    <div><img src={Imagen4} alt="GTA V" /></div>
                    <div><img src={Imagen7} alt="Mirror Edge" /></div>
                    <div><img src={Imagen15} alt="GTA V" /></div>
                    <div><img src={Imagen13} alt="GTA V" /></div>
                </React.Fragment> :
                <React.Fragment>
                    <div><img src={Imagen} alt="Mirror Edge" /></div>
                    <div><img src={Imagen11} alt="GTA V" /></div>
                    <div><img src={Imagen14} alt="GTA V" /></div>
                    <div><img src={Imagen8} alt="Mirror Edge" /></div>
                    <div><img src={Imagen9} alt="GTA V" /></div>
                </React.Fragment> }
            </OwlCarousel>
        )
    }
}

export default SliderM;