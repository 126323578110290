import React, { Component } from "react";
import CardList from '../../components/cards/card-list';
import { Link } from "react-router-dom";
import Request from '../../core/httpClient';

const req = new Request();

class CatalogViewList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: []
        }
    }

    async load() {
        const data = {};
        const res = await req.get('/products/filters', data);
        if (res.products) {
            console.log(res.products);
            this.setState({ products: res.products });
        }
    }

    render() {
        let locale = window.localStorage.getItem('cto_locale');
        if (!locale) {
            locale = 'es';
        }

        let products = [];
        if (this.props.products) {
            products = this.props.products.slice(
                (this.props.page - 1) * 9,
                this.props.page * 9
            );
        }

        return (
            <div className="catalog-view-list">
                <div className="catalog-cards-list column align-center justify-center">
                    {products.map((tour) =>
                        <Link to={`/detalle/producto/${tour.id_producto}`}><CardList key={tour.id_producto} {...tour} locale={locale} /></Link>
                    )}
                </div>
            </div>
        )
    }

    componentDidMount() {
        this.load();
    }
}

export default CatalogViewList;