import React, { Component } from 'react';
import { Consumer } from '../context';
import { FormattedMessage, injectIntl } from 'react-intl';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';

class CheckSuccess extends Component {
    componentDidMount() {
        this.props.context.cleanCart();
    }

    home() {
        //this.props.context.cleanCart();
        this.props.history.push('/');
    }

    render() {
        return (
            <div className="check-success column">
                <Header />
                <div className="checkpay justify-center">
                    <div className="container column">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" >
                            <circle className="path circle" fill="none" stroke="#000000" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                            <polyline className="path check" fill="none" stroke="#000000" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                        </svg>
                        <div className="white-space-32" />
                        <h2 className="color-black text-center">
                            <FormattedMessage
                                defaultMessage="¡FELICIDADES, COMPRA EXITOSA!"
                                id="compra_exitosa" />
                        </h2>
                        <div className="white-space-16" />
                        <div className="btn-container-check-status justify-center">
                            <a className="btn btn-black text-center color-white" onClick={this.home.bind(this)}>
                                <FormattedMessage
                                    defaultMessage="INICIO"
                                    id="inicio" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="sl-footer align-center">
                    <Footer />
                </div>
            </div>
        );
    }
}

export default Consumer(injectIntl(CheckSuccess));