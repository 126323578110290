import React, { Component } from "react";
import CardGrid from '../../components/cards/card-grid';
import { Link } from "react-router-dom";
import Request from '../../core/httpClient';

const req = new Request();

class CatalogViewGrid extends Component {
    render() {
        let locale = window.localStorage.getItem('cto_locale');
        if (!locale) {
            locale = 'es';
        }

        let products = [];
        if (this.props.products) {
			console.log(this.props.products)
            products = this.props.products.slice(
                (this.props.page - 1) * 9,
                this.props.page * 9
            );
        }
        
        return (
            <div className="catalog-view-grid">
                <div className="row-responsive align-center justify-center">
                    <div className="catalog-cards-grid align-center justify-center">
                        {products.map((tour) =>
                            <Link key={tour.id_producto} to={`/detalle/producto/${tour.id_producto}`}><CardGrid {...tour} locale={locale}/></Link>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    /*componentDidMount() {
        this.loadProducts();
    }*/
}

export default CatalogViewGrid;