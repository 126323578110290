export function Login(data) {
	//console.log("Login");
	window.localStorage.setItem('tousr', JSON.stringify(data));
	window.localStorage.removeItem('cart');
	this.setState({ user: data });

	return data
}

export function Logout() {
	window.localStorage.removeItem('tousr');
	window.localStorage.removeItem('cart');
	this.setState({ user: { auth: false } });

	return {auth: false}
}