import React, { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import Imagen from '../../img/nado-xenotes-03.jpg';
import NumberFormat from "react-number-format";
import { Consumer } from "../../context";
import cardReservation from "./card-reservation";
import moment from 'moment-timezone';
import { isMobile } from "react-device-detect";

class CardItemCart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subadults: 0,
            subchildren: 0,
            sub: 0
        }
    }

    render() {
        let locale = window.localStorage.getItem('cto_locale');
        if (!locale) {
            locale = 'es';
        }

        let cart = [], titulo = '', index = 0, descuentoAdicional = 0, agencia = false, descuento_adulto = 0, descuento_menor = 0;
        if (this.props.position) {
            index = this.props.position;
        }

        if (this.props.cart) {
            cart = this.props.cart;   
            console.log("First: ", cart);
            if (this.props.cart.product) {
                if (this.props.cart.product.titulo) {
                    titulo = JSON.parse(this.props.cart.product.titulo);
                    titulo = titulo[locale];
                }

                if (this.props.cart.product.agencia) {
                    agencia = this.props.cart.product.agencia;
                }

                if (this.props.cart.product.descuento_adulto) {
                    descuento_adulto = this.props.cart.product.descuento_adulto
                }

                if (this.props.cart.product.descuento_menor) {
                    descuento_menor = this.props.cart.product.descuento_menor
                }
            }
        }

        if (this.props.descuentoAdicional !== 0) {
            if (this.props.descuentoAdicional === 1) {
                descuentoAdicional = 0.95;
            }

            if (this.props.descuentoAdicional === 2) {
                descuentoAdicional = 0.9;
            }
        }

        let descuento = 0;
        if (this.props.cart.product.descuento) {
            descuento = this.props.cart.product.descuento;
            //Calculo de descuento
            if (descuento > 0) {
                descuento = descuento / 100;
            }
        }
        
        let tc = 1;
        const exrate = window.localStorage.getItem('exrate');
        if(exrate === 'MXN') {
            tc = this.props.context.tc;
        } else if(exrate === 'USD') {
            tc = 1;
        }

		//Personas Tours Privados
		let paxDisabled = false;
		if(this.props.cart.opcion && this.props.cart.product.categoria == 31) {
			if (this.props.cart.opcion.personas) {
				paxDisabled = this.props.cart.opcion.personas >= 1? true : false;
			}
		}

        return (
            <div className="row item-cart">
            <a onClick={this.props.removeFCart.bind(this, cart, this.props.position)}><i className="fas fa-trash font-regular color-gray delete-icon remove-icon"></i></a>
            <div className="column tour-column align-center">
                <div className="white-space-8"></div>
                <img className="cover-img" src={cart.product.imagen}></img>     
                <div className="white-space-8"></div>                           
                <p className="font-small weight-bold color-green">
                    {titulo}
                </p>  
            </div>
            <div className="column">
                <div className="white-space-8"></div>
                {isMobile ?
                <div className="row">
                    <div className="column align-center">
                        <p className="font-small weight-semi">
                            <FormattedMessage defaultMessage="Adultos: " id="producto_adultos" />
                        </p>
                    </div>
                    <div className="row align-center justify-center">
                        <p className="font-small weight-semi">
                            <NumberFormat
                                value={!agencia ? (descuento_adulto * tc) : cart.product.precio_adulto * tc}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                                decimalScale={2}
                                fixedDecimalScale={true}
                            />
                        </p>
                    </div>
                    <div className="column align-center">
                        <input className="input input-cart"
                            type="number"
                            name="adults"
                            min="1"
                            max="99"
                            value={cart.adultos}
                            onChange={(event) => {
                                if (event.target.value < 0 || parseFloat(event.target.value) > 99) {
                                    alert("Cantidad debe ser mayor a 0 y menor a 99");
                                    return;
                                } else {
                                    const sub = parseInt(event.target.value);
                                    cart.adultos = sub;
                                    let carrito = window.localStorage.getItem('cart');
                                    if (carrito) {
                                        carrito = JSON.parse(carrito);
                                    }
                                    Object.assign(carrito[index], {
                                        adultos: sub,
                                        total: !agencia ? (sub * descuento_adulto) + (cart.menores * descuento_menor) : descuentoAdicional !== 0 ? (sub * cart.product.precio_adulto * descuentoAdicional) + (cart.menores * cart.product.precio_menor * descuentoAdicional) : (sub * cart.product.precio_adulto) + (cart.menores * cart.product.precio_menor)
                                    });
                                    this.props.updateCart(carrito);
                                    carrito = JSON.stringify(carrito);
                                    window.localStorage.setItem('cart', carrito);
                                }
                            }}
                            required />
                    </div>
                    <div className="last-price row justify-end align-center">
                        <p className="font-small weight-semi">
                            <NumberFormat
                                value={!agencia ? Math.ceil((paxDisabled ? 1 : cart.adultos) * (descuento_adulto * tc)) : descuentoAdicional !== 0 ? Math.ceil((paxDisabled ? 1 : cart.adultos) * (cart.product.precio_adulto * descuentoAdicional * tc)) : Math.ceil((paxDisabled ? 1 : cart.adultos) * (cart.product.precio_adulto * tc))}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                                decimalScale={2}
                                fixedDecimalScale={true}
                            />
                        </p>
                    </div>
                </div>
                :
                <div className="row">
                    <div className="column align-center">
                        <p className="font-small weight-semi">
                            <FormattedMessage defaultMessage="Adultos: " id="producto_adultos" />
                        </p>
                    </div>
                    <div className="row align-center justify-center">
                        <p className="exrate">{exrate}</p>
                        <p className="font-small weight-semi">
                            <NumberFormat
                                value={!agencia ? (descuento_adulto * tc) : cart.product.precio_adulto * tc}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                                decimalScale={2}
                                fixedDecimalScale={true}
                            />
                        </p>
                    </div>
                    <div className="column align-center">                               
                        <input className="input input-cart"
                            type="number"
                            name="adults"
                            min="1"
                            max="99"
                            value={cart.adultos}
                            onChange={(event) => {
                                if (event.target.value < 0 || parseFloat(event.target.value) > 99) {
                                    alert("Cantidad debe ser mayor a 0 y menor a 99");
                                    return;
                                } else {
                                    const sub = parseInt(event.target.value);
                                    cart.adultos = sub;
                                    let carrito = window.localStorage.getItem('cart');
                                    if (carrito) {
                                        carrito = JSON.parse(carrito);
                                    }
                                    Object.assign(carrito[index], { 
                                        adultos: sub, 
                                        total: !agencia ? 
                                        (sub * descuento_adulto) + (cart.menores * descuento_menor) : 
                                        descuentoAdicional !== 0 ? 
                                        Math.ceil((sub * cart.product.precio_adulto * descuentoAdicional) + (cart.menores * cart.product.precio_menor * descuentoAdicional)) : 
                                        Math.ceil((sub * cart.product.precio_adulto) + (cart.menores * cart.product.precio_menor))});
                                    this.props.updateCart(carrito);
                                    carrito = JSON.stringify(carrito);
                                    window.localStorage.setItem('cart', carrito);
                                }
                            }}  
                       		required
							disabled={paxDisabled}/>
                    </div>
                    <div className="last-price row justify-end align-center">
                        <p className="exrate">{exrate}</p>
                        <p className="font-small weight-semi">
                            <NumberFormat
                                value={!agencia ? 
                                    Math.ceil((paxDisabled ? 1 : cart.adultos) * (descuento_adulto * tc)) : descuentoAdicional !== 0 ? 
                                    (paxDisabled ? 1 : cart.adultos) * Math.ceil(cart.product.precio_adulto * descuentoAdicional) * tc : 
                                    (paxDisabled ? 1 : cart.adultos) * Math.ceil(cart.product.precio_adulto) * tc}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                                decimalScale={2}
                                fixedDecimalScale={true}
                            />
                        </p>
                    </div>
                </div>}
                <div className="white-space-16"></div>
                {isMobile ?
                <div className="row">
                    <div className="column align-center">
                        <p className="font-small weight-semi">
                            <FormattedMessage defaultMessage="Niños: " id="producto_niños" />
                        </p>
                    </div>
                    <div className="row justify-center align-center">
                        <p className="font-small weight-semi">
                            <NumberFormat
                                value={!agencia ? (descuento_menor * tc) : cart.product.precio_menor * tc}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                                decimalScale={2}
                                fixedDecimalScale={true}
                            />
                        </p>
                    </div>
                    <div className="column align-center">
                        <input className="input input-cart"
                            type="number"
                            name="children"
                            max="99"
                            value={cart.menores}
                            onChange={(event) => {
                                if (parseFloat(event.target.value) < 0 || parseFloat(event.target.value) > 99) {
                                    alert("Cantidad debe ser mayor a 0 y menor a 99");
                                    return;
                                } else {
                                    const sub = parseInt(event.target.value);
                                    cart.menores = sub;
                                    let carrito = window.localStorage.getItem('cart');
                                    if (carrito) {
                                        carrito = JSON.parse(carrito);
                                    }
                                    Object.assign(carrito[index], {
                                        menores: sub,
                                        total: !agencia ? (sub * descuento_menor) + (cart.adultos * descuento_adulto) : descuentoAdicional !== 0 ? 
                                        (sub * Math.ceil(cart.product.precio_menor * descuentoAdicional)) + (cart.adultos * Math.ceil(cart.product.precio_adulto * descuentoAdicional)) :
                                        (sub * cart.product.precio_menor) + (cart.adultos * cart.product.precio_adulto)
                                    });
                                    this.props.updateCart(carrito);
                                    carrito = JSON.stringify(carrito);
                                    window.localStorage.setItem('cart', carrito);
                                }
                            }}
							required
							disabled={paxDisabled}/>
                    </div>
                    <div className="last-price row justify-end align-center">
                        <p className="font-small weight-semi">
                            <NumberFormat
                                value={!agencia ? (paxDisabled ? 1 : cart.menores) * (descuento_menor * tc) : descuentoAdicional !== 0 ? 
                                    (paxDisabled ? 1 : cart.menores) * (Math.ceil(cart.product.precio_menor * descuentoAdicional) * tc) :
                                    (paxDisabled ? 1 : cart.menores) * (cart.product.precio_menor * tc)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                                decimalScale={2}
                                fixedDecimalScale={true}
                            />
                        </p>
                    </div>
                </div>
                :
                <div className="row">
                    <div className="column align-center">
                        <p className="font-small weight-semi">
                            <FormattedMessage defaultMessage="Niños: " id="producto_niños" />
                        </p>
                    </div>
                    <div className="row justify-center align-center">
                        <p className="exrate">{exrate}</p>
                        <p className="font-small weight-semi">
                            <NumberFormat
                                value={!agencia ? (cart.product.descuento_menor * tc) : cart.product.precio_menor * tc}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                                decimalScale={2}
                                fixedDecimalScale={true}
                            />
                        </p>
                    </div>
                    <div className="column align-center">                               
                        <input className="input input-cart"
                            type="number"
                            name="children"
                            max="99"
                            value={cart.menores}
                            onChange={(event) => {
                                if (parseFloat(event.target.value) < 0 || parseFloat(event.target.value) > 99) {
                                    alert("Cantidad debe ser mayor a 0 y menor a 99");
                                    return;
                                } else {
                                    const sub = parseInt(event.target.value);
                                    cart.menores = sub;
                                    let carrito = window.localStorage.getItem('cart');
                                    if (carrito) {
                                        carrito = JSON.parse(carrito);
                                    }
                                    Object.assign(carrito[index], { 
                                        menores: sub, 
                                        total: !agencia ? (sub * descuento_menor) + (cart.adultos * descuento_adulto) : descuentoAdicional !== 0 ? 
                                        (sub * Math.ceil(cart.product.precio_menor * descuentoAdicional) + (cart.adultos * Math.ceil(cart.product.precio_adulto * descuentoAdicional))) :
                                        Math.ceil((sub * cart.product.precio_menor) + (cart.adultos * cart.product.precio_adulto))});
                                    this.props.updateCart(carrito);
                                    carrito = JSON.stringify(carrito);
                                    window.localStorage.setItem('cart', carrito);
                                }
                            }}  
							required
							disabled={paxDisabled}/>
                    </div>
                    <div className="last-price row justify-end align-center">
                        <p className="exrate">{exrate}</p>
                        <p className="font-small weight-semi">
                            <NumberFormat
                                value={!agencia ? (paxDisabled ? 1 : cart.menores) * (descuento_menor * tc) : descuentoAdicional !== 0 ? 
                                (paxDisabled ? 1 : cart.menores) * (Math.ceil(cart.product.precio_menor * descuentoAdicional) * tc) :
                                Math.ceil((paxDisabled ? 1 : cart.menores) * (cart.product.precio_menor * tc))}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                                decimalScale={2}
                                fixedDecimalScale={true}
                            />  
                        </p>
                    </div>
                </div>}
                <div className="row justify-end">
                    <div className="divider"></div>
                </div>
                <div className="white-space-16"></div>
                <div className="row">
                    <div className="column align-center">
                        <p className="font-small weight-semi">
                            <FormattedMessage defaultMessage="Fecha: " id="producto_fecha" />
                        </p>
                    </div>
                    <div className="column align-center">
                        <p className="font-small color-green weight-semi">
                            {moment(cart.fecha).format('DD/MM/YYYY')}
                        </p>
                    </div>
                    <div className="column align-center">   
                        <p className="font-regular weight-semi"></p>    
                    </div>
                    {isMobile ?
                    <div className="last-price row justify-end align-center">
                        <p className="font-regular weight-semi color-red">
                            <NumberFormat
                                value={!agencia ? ((((paxDisabled ? 1 : cart.adultos) * (descuento_adulto)) + (cart.menores * (descuento_menor))) * tc).toFixed(2) : 
                                    descuentoAdicional !== 0 ? 
                                    ((((paxDisabled ? 1 : cart.adultos) * Math.ceil(cart.product.precio_adulto * descuentoAdicional)) + (cart.menores * Math.ceil(cart.product.precio_menor * descuentoAdicional))) * tc).toFixed(2) : 
                                    ((((paxDisabled ? 1 : cart.adultos) * (cart.product.precio_adulto)) + (cart.menores * (cart.product.precio_menor))) * tc).toFixed(2)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                                decimalScale={2}
                                fixedDecimalScale={true}
                            />
                        </p>
                    </div>
                    :   
                    <div className="last-price row justify-end align-center">
                        <p className="exrate">{exrate}</p>
                        <p className="font-regular weight-semi color-red">
                            <NumberFormat
                                value={!agencia ? ((((paxDisabled ? 1 : cart.adultos) * (descuento_adulto)) + (cart.menores * (descuento_menor))) * tc).toFixed(2) : descuentoAdicional !== 0 ? 
                                    ((((paxDisabled ? 1 : cart.adultos) * Math.ceil(cart.product.precio_adulto * descuentoAdicional)) + (cart.menores * Math.ceil(cart.product.precio_menor * descuentoAdicional))) * tc).toFixed(2) : Math.ceil((((paxDisabled ? 1 : cart.adultos) * (cart.product.precio_adulto)) + (cart.menores * (cart.product.precio_menor))) * tc).toFixed(2)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                                decimalScale={2}
                                fixedDecimalScale={true}
                            />    
                        </p>
                    </div>}
                </div>
            </div>
        </div>
        )
    }
}

export default Consumer(injectIntl(CardItemCart));