import React, { Component } from "react";
import { Consumer } from '../../context';
import Imagen1 from '../../img/nado-xenotes-03.jpg';
import { FormattedMessage, injectIntl } from 'react-intl';
import NumberFormat from "react-number-format";

class CardList extends Component {
    render() {
        let { titulo, precio_adulto, descripcion, descripcion_corta, imagen, descuento } = this.props;
        if (titulo) {
            titulo = JSON.parse(titulo);
            titulo = titulo[this.props.locale];
        } else {
            titulo = '';
        }

        if (descripcion) {
            descripcion = JSON.parse(descripcion);
            descripcion = descripcion[this.props.locale];
        }

        //Calculo de descuento
        if (descuento > 0) {
            descuento = descuento / 100;
        }

        if (descripcion_corta) {
            descripcion_corta = JSON.parse(descripcion_corta);
            descripcion_corta = descripcion_corta[this.props.locale];
        } else {
            descripcion_corta = '';
        }

        let tc = 1;
        const exrate = window.localStorage.getItem('exrate');
        if (exrate === 'MXN') {
            tc = this.props.context.tc;
        } else if (exrate === 'USD') {
            tc = 1;
        }

        return (
            <div className="card-list">
                <div className="list-image justify-center responsive-img">
                    <img src={imagen} alt="Imagen" />
                </div>
                <div className="list-info column justify-center">
                    <div className="list-i row-responsive justify-center align-start">
                        <div className="ft-text column">
                            <div className="first-text row-responsive align-center">
                                <div className="ft-title">
                                    <h3>{titulo}</h3>
                                </div>
                            </div>
                            <div className="second-text">
                                <div className="row">
                                    <ul className="list-inline">
                                        <li className="place">{descripcion_corta}</li>
                                        {/*<li className="days">5 Días / 4 noches</li>*/}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="white-space-16"></div>
                    <div className="third-text">
                        <p className="truncate-ellipsis">
                            {descripcion}
                        </p>
                    </div>
                    <div className="white-space-16"></div>
                    <div className="row ft-price justify-end align-center">
                        {descuento !== null && descuento > 0 ?
                            <p className="font-small line-through color-darkgray">
                                <NumberFormat
                                    value={(precio_adulto * tc).toFixed(2)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                />
                            </p> : null} &nbsp;
                        <h2>${((precio_adulto - (precio_adulto * descuento)) * tc).toFixed(2)}</h2>
                    </div>
                </div>
                {/*{descuento !== null ?
                <div className="ribbon-wrapper-red">
                    <div className="ribbon-red"><FormattedMessage defaultMessage="OFERTA" id="producto_oferta" /></div>
                </div> : null}*/}
            </div>
        )
    }
}

export default Consumer(injectIntl(CardList));