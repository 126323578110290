import React, { Component } from "react";
import { FormattedHTMLMessage, FormattedMessage, FormattedPlural, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {Consumer} from '../../context';
import MexicoFlag from '../../img/mexico.png';
import USFlag from '../../img/usflag.png';
import BrazilFlag from '../../img/brazil.png';
import Logo from '../../img/Imagotipo.png';
import Request from '../../core/httpClient';
import socket from '../../core/socket';
//WebComponents
import { cifrar } from '../../components/encrypt/encrypt';
import { isMobile } from "react-device-detect";

const req = new Request();

const onClickOutsideListener = () => {
	//alert("click outside")
	document.removeEventListener("click", onClickOutsideListener)

	const login = document.getElementById('login');
	if (login) {
		if (login.classList.contains('flex')) {
			login.classList.add('hidden');
			login.classList.remove('flex');
		} else {
			login.classList.add('flex');
			login.classList.remove('hidden');
		}
	}
}

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tipoCambio: "USD",
			user: {},
			loading_login: false
		};
	}

	componentDidMount() {
		const exrate = window.localStorage.getItem('exrate');
		if(!exrate) {
			window.localStorage.setItem('exrate', "USD");
		} else {
			this.setState({tipoCambio: exrate})
		}
		this.getTc();
		socket.on('newTC', (data) => {
			this.getTc();
		})

		this.getUser();
	}

	async getTc() {
		const res = await req.post('/admin/tc/tcday');
		if(res.tc) {
			this.props.context.getTC(res.tc);
		} else {
			alert('error TC');
		}
	}

	async getUser() {
		let user = window.localStorage.getItem("tousr");

		if (user) {
			console.log(user);
			user = JSON.parse(user);

			const resUser = await req.post('/usuarios/tokuser', { id: user.id });
			console.log(resUser.data);
			let inicial = '';
			if (resUser) {
				if (resUser.data) {
					let nombre = resUser.data.nombre.split(' ');
					nombre.forEach((x, i) => {
						if (i <= 1) {
							inicial = inicial + x.substring(0, 1);
							inicial = inicial.toUpperCase();
						}
					});
					this.setState({ user: resUser.data, inicial });
				}
			}
		}
	}

	changeLocale(event) {
		const locale = event.target.value;
		if(locale) {
			window.localStorage.setItem('cto_locale', locale);
			window.location.reload();
		}
	}

	sendWhats() {
		window.location = `https://api.whatsapp.com/send?phone=521${9984123654}`;
	}

	exchangeRate() {
		if (this.state.tipoCambio === "USD") {
			this.setState({ tipoCambio: "MXN" });
			window.localStorage.setItem('exrate', "MXN");
		} else {
			this.setState({ tipoCambio: "USD" });
			window.localStorage.setItem('exrate', "USD");
		}
		window.location.reload();
	}

	/*showLanguage() {
		//Menú de idiomas.
		let x = document.getElementById("more-categories");
		if (x.classList.contains('showMore')) {
			x.classList.remove('showMore');
			x.classList.add('more-categories');
		} else {
			x.classList.add('showMore');
			x.classList.remove('more-categories');
		}
	}*/

	filFromHome(ruta) {	
		window.localStorage.removeItem('cats');
		window.localStorage.removeItem('page');
		window.localStorage.removeItem('view');
		window.localStorage.removeItem('order');
		window.location = ruta;
	}

	async login(e) {
		e.preventDefault();

		this.setState({ loading_login: true });

		const form = e.target;
		const password = cifrar(form.pass.value);
		let user = form.user.value;
		let pass = form.pass.value;
		const data = {
			username: user,
			password: password
		}

		const res = await req.post('/usuarios/login', data);
		if (res.user) {
			console.log(res.user);
			this.props.context.login({ id: res.user, auth: true });
			window.location.reload();
		} 

		if (res.error) {
			user = '';
			pass = '';

			alert(res.message);
		}

		this.setState({ loading_login: false });
	}

	logout() {
		this.props.context.logout();
		window.location.reload();
	}

	openLogin() {
		const login = document.getElementById('login');
		if (login) {
			if (login.classList.contains('flex')) {
				login.classList.add('hidden');
				login.classList.remove('flex');
			} else {
				login.classList.add('flex');
				login.classList.remove('hidden');
			}
		}
	}

	closeLogin() {
		const login = document.getElementById('login');
		if (login) {
			if (login.classList.contains('flex')) {
				login.classList.add('hidden');
				login.classList.remove('flex');
			}
		}
	}

	render() {
		let locale = window.localStorage.getItem('cto_locale');
		if (!locale) {
			locale = 'es';
		}

		let numProducts = 0;
		let cart = window.localStorage.getItem('cart');
		if (cart) {
			cart = JSON.parse(cart);
			numProducts = cart.length;
		} else {
			cart = [];
		}

		return (
			<header className="header-web justify-center" >
				<div className="container">
					<div className="header-info align-center row">
						<div className="left justify-center align-center">
							<div className="column">
								<Link to = "/" className="responsive-img justify-center align-center">
									<img src={Logo} alt="Vamos Cancún Logo" title="Vamos Cancún Logo" className="logo cover-img"/>
								</Link>
							</div>
						</div>
						<div className="right justify-end align-center">
							<div className="right-left align-center justify-end">
								<div className="private-tour nav-item justify-end">
									<Link onClick={this.filFromHome.bind(this, `/catalogo/cat/${31}`)} className="weight-semi text-center">
										<FormattedMessage defaultMessage="Tours privados" id="header_tour_privado" />
									</Link>
								</div>
								<div className="xcaret nav-item auto">
									<Link onClick={this.filFromHome.bind(this, `/catalogo/cat/${29}`)} className="weight-semi">
										<FormattedMessage defaultMessage="Xcaret" id="header_xcaret" />
									</Link>
								</div>
								<div className="activities nav-item auto">
									<Link /*to={`/catalogo/all/${0}`}*/ onClick={this.filFromHome.bind(this, `/catalogo/all/${0}`)} className="weight-semi">
										<FormattedMessage defaultMessage="Actividades" id="header_actividades" />
									</Link>
								</div>
								<div className="us nav-item auto">
									<Link to="/nosotros" className="weight-semi text-center">
										<FormattedMessage defaultMessage="Nosotros" id="header_nosotros" />
									</Link>
								</div>
								<div className="contacto whats-provider justify-center">
									<Link to="/contacto" className="weight-semi">
										<FormattedMessage defaultMessage="Contacto" id="header_contacto" />
									</Link>
								</div>
								<div className="whats whats-provider justify-center">
									<div className="weight-semi justify-center align-center" /*onClick={this.sendWhats.bind(this)} target="_blank" rel="noopener"*/>
										<a href="https://api.whatsapp.com/send?phone=5219983298109" target="_blank" rel="noopener">
											<i className="fab fa-whatsapp"></i>
										</a>
									</div>
								</div>
							</div>
							<div className="right-right align-center justify-end">
								<div className="exchange-rate auto justify-center align-center" onClick={this.exchangeRate.bind(this)}>
									<i className="fas fa-money-bill color-pink"></i> &nbsp;
								<label className="weight-semi">{this.state.tipoCambio}</label>
								</div>
								<div className="language auto row justify-center align-center">
									<i className="fas fa-globe-americas color-pink"></i>
									<select name="language" id="language" className="font-small weight-semi" value={window.localStorage.getItem('cto_locale')} onChange={this.changeLocale.bind(this)}>
										<option value="es">ES</option>
										<option value="en">EN</option>
										<option value="pt">PT</option>
									</select>
								</div>
								{/*<div className="option-nav w3-bar-item select-language justify-center">
							<div className="idioma row align-center">
								<a className="globe" onClick={this.showLanguage.bind(this)}><img src={MexicoFlag} />{/*<i className="fas fa-globe-americas"></i>}</a>
								<label>{locale === 'es' ? 'Es' : locale === 'en' ? 'En' : locale === 'pt' ? 'Pt' : null}</label>
							</div>
							<div className="idioma row align-center">
								<a className="globe" onClick={this.showLanguage.bind(this)}><img src={USFlag} />{/*<i className="fas fa-globe-americas"></i>}</a>
								<label>{locale === 'es' ? 'Es' : locale === 'en' ? 'En' : locale === 'pt' ? 'Pt' : null}</label>
							</div>
							<div className="idioma row align-center">
								<a className="globe" onClick={this.showLanguage.bind(this)}><img src={BrazilFlag} />{/*<i className="fas fa-globe-americas"></i>}</a>
								<label>{locale === 'es' ? 'Es' : locale === 'en' ? 'En' : locale === 'pt' ? 'Pt' : null}</label>
							</div>
						</div>*/}
								{/*<div className="option-nav w3-bar-item select-language justify-center">
							<div className="idioma row align-center">
								<a className="globe" onClick={this.showLanguage.bind(this)}><img src={MexicoFlag} />{/*<i className="fas fa-globe-americas"></i>}</a>
								<label>{locale === 'es' ? 'Es' : locale === 'en' ? 'En' : locale === 'pt' ? 'Pt' : null}</label>
							</div>
							{locale === 'es' ?
							<div id="others" className="language-dropdown-content">
								<a className="w3-bar-item w3-button text-center" onClick={this.changeLocale.bind(this, 'en')}>
									<FormattedMessage defaultMessage="En" id="idioma_ingles" />
								</a>
								<a className="w3-bar-item w3-button text-center" onClick={this.changeLocale.bind(this, 'pt')}>
									<FormattedMessage defaultMessage="Pt" id="idioma_portugues" />
								</a>
							</div> : locale === 'en' ?
							<div className="language-dropdown-content">
								<a className="w3-bar-item w3-button text-center" onClick={this.changeLocale.bind(this, 'es')}>
									<FormattedMessage defaultMessage="Es" id="idioma_español" />
								</a>
								<a className="w3-bar-item w3-button text-center" onClick={this.changeLocale.bind(this, 'pt')}>
									<FormattedMessage defaultMessage="Pt" id="idioma_portugues" />
								</a>
							</div> : 
							<div className="language-dropdown-content">
								<a className="w3-bar-item w3-button text-center" onClick={this.changeLocale.bind(this, 'es')}>
									<FormattedMessage defaultMessage="Es" id="idioma_español" />
								</a>
								<a className="w3-bar-item w3-button text-center" onClick={this.changeLocale.bind(this, 'en')}>
									<FormattedMessage defaultMessage="En" id="idioma_ingles" />
								</a>
							</div> }
						</div>*/}
								<div className="option-nav auto cart-nav justify-center"><a className="w3-bar-item" onClick={() => window.location = '/carrito'}>
									<i className="fas fa-shopping-cart color-pink"></i>
									<div className="numbercart justify-center align-center">{numProducts}</div>
								</a></div>
								{Object.keys(this.state.user).length !== 0 ?
									<div className="user justify-center align-center auto" onClick={this.logout.bind(this)}>
										<p>SALIR</p> <i className="fas fa-sign-out-alt space-8"></i>
										{/*<div className="circle-user justify-center align-center auto">
											<h5>{this.state.inicial}</h5>
										</div>*/}
									</div>
								 	:
									<div className="login">
										<i className="fas fa-user color-pink" onClick={this.openLogin.bind(this)}></i>
										{/*<div className="login-box" id="login">
											<form className="box column justify-center align-center" onSubmit={this.login.bind(this)}>
												<div className="column containe justify-center">
													{/*<div className="white-space-16"></div>
												<div className="login-title justify-start">
													<h4 className="login-h3 text-center">INICIAR SESIÓN</h4>
												</div>
													<div className="white-space-24"></div>
													<div className="box-body column">
														<div className="usuar column">
															{/*<label>Usuario</label>
														<div className="white-space-8"></div>
															<input className="input-login" placeholder="Usuario" name="user" id="user" type="text" required />
															<div className="cuadrito-user justify-center align-center">
																<i className="fas fa-user color-pink"></i>
															</div>
														</div>
														<div className="white-space-16"></div>
														<div className="passw column">
															{/*<label>Contraseña</label>
														<div className="white-space-8"></div>
															<input className="input-login" placeholder="Contraseña" name="pass" id="pass" type="password" required />
															<div className="cuadrito-pass justify-center align-center">
																<i className="fas fa-key color-pink"></i>
															</div>
														</div>
														{/*<div className="white-space-16"></div>
													<div className="input-container">
														<label className="lbl" htmlFor="user">Usuario</label>
														<input className="ipt" name="user" id="user" type="text" required />
														<div className="bar"></div>
													</div>
													<div className="white-space-16"></div>
													<div className="input-container">
														<label className="lbl" htmlFor="pass">Contraseña</label>
														<input className="ipt" name="pass" id="pass" type="password" required />
														<div className="bar"></div>
													</div>
														<div className="white-space-16"></div>
														<div className="justify-end">
															<button type="submit" className="btn btn-login">Iniciar sesión</button>
														</div>
													</div>
												</div>
											</form>
										</div>*/}
										<div class="login-wrap column hidden" id="login" onMouseLeave={() => !isMobile && onClickOutsideListener()}>
											<div className="close-x justify-end" onClick={this.closeLogin.bind(this)}>
												<p className="text-right">Cerrar</p>
											</div>
											<div class="icon d-flex align-center justify-center">
												<i className="far fa-user"></i>
											</div>
											<h3 class="text-center mb-4">Iniciar sesión</h3>
											<div className="white-space-16"></div>
											<form action="#" class="login-form column justify-center align-center" onSubmit={this.login.bind(this)}>
												<div class="form-group">
													<input type="text" class="form-control rounded-left" placeholder="Usuario" required name="user" id="user" />
												</div>
												<div className="white-space-8"></div>
												<div class="form-group d-flex">
													<input type="password" class="form-control rounded-left" placeholder="Contraseña" name="pass" id="pass" required />
												</div>
												<div className="white-space-8"></div>
												<div class="form-group">
													<button type="submit" class="form-control btn btn-primary rounded submit px-3">{this.state.loading_login ? 'Iniciando...' : 'Entrar'}</button>
												</div>
											</form>
										</div>
									</div>
								}
							</div>
						</div>
					</div>
				</div>
    		</header>
		)
	}
}

export default Consumer(injectIntl(Header));

{/*<header className="column">
	<div className="header-top">
		<label>Idioma</label>
		<select value={window.localStorage.getItem('cto_locale')} onChange={this.changeLocale.bind(this)}>
			<option value="es">Español</option>
			<option value="en">English</option>
			<option value="pt">Portuguese</option>
		</select>
	</div>
</header>*/}