/**
 *  routes.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Manejo de todas las rutas de la aplicación
 * 	@process: 4
 */

 
import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { IntlProvider, addLocaleData } from "react-intl";

// Conexión con el Context API
import { GlobalContext } from "../context";

// Páginas Web
import Home from '../pages/home';
import Catalog from '../pages/catalog';
import ProductDetail from '../pages/product-detail';
import Cart from '../pages/cart';
import CartSuccess from '../pages/check-success';
import CartFail from '../pages/check-fail';
import SliderM from '../pages/slider-m';
import AboutUs from '../pages/about-us';
import TermsConditions from '../pages/terms_conditions';
import Contact from '../pages/contact';
import SuccessfulReservation from '../pages/reservation-success';

//locales
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import pt from 'react-intl/locale-data/pt';
import messages_es from './locales/es.json';
import messages_en from './locales/en.json';
import messages_pt from './locales/pt.json';
import socket from './../core/socket';

addLocaleData([...en, ...es, ...pt]);

//Mensajes Idioma
const messages = {
	'es': messages_es,
	'en': messages_en,
	'pt': messages_pt
};
//Idioma Seleccionado
let locale = window.localStorage.getItem('cto_locale');
if(!locale) {
	locale = 'es';
	window.localStorage.setItem('cto_locale', 'es');
}

const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
    	{ ...rest }
		render = {
			props => {
				const usuario = window.localStorage.getItem("cto_user");
				if (usuario) {
					return <Component { ...props } />;
				} else {
					return (
						<Redirect to = {{ pathname: "/", state: { from: props.location } }} />
					);
				}
			}
		}
  	/>
);

class Routes extends Component {
	render() {
		return (
			<GlobalContext>
				<IntlProvider locale={locale} messages={messages[locale]}>
					<BrowserRouter>
						<div className="flex main">
							<div className="column">
								<Switch>
									<Route path="/" exact component={Home} />
									<Route path="/catalogo/:tipo/:filtro" exact component={Catalog} />
									<Route path="/detalle/producto/:id" exact component={ProductDetail} />
									<Route path="/carrito" exact component={Cart} />
									<Route path="/carrito/success" exact component={CartSuccess} />
									<Route path="/carrito/fail" exact component={CartFail} />
									<Route path="/slider" exact component={SliderM} />
									<Route path="/nosotros" exact component={AboutUs} />
									<Route path="/terminos" exact component={TermsConditions} />
									<Route path="/carrito/reservacion" exact component={SuccessfulReservation} />
									<Route path="/contacto" exact component={Contact} />
									<PrivateRoute path="/perfil" exact component={Home}></PrivateRoute>
								</Switch>
							</div>
						</div>
					</BrowserRouter>
				</IntlProvider>
			</GlobalContext>
		)
	}
}

export default Routes;