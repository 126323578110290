import React, { Component } from "react";
import Slider from '../../pages/slider-m';

class TopOfPage extends Component {
    render() {
        let image = '', text = '', tamaño = '';
        if (this.props.data) {
            image = this.props.data.image;
            text = this.props.data.text;
            tamaño = this.props.data.tamaño;
        }

        return (
            <section className="top-of-page" id="top-of-page">
                {/*<div className="top-imagen" style={{ "height": tamaño }}>
                    <img src={image} alt=""/>
                </div>*/}
                <div className="top-imagen" style={{ "height": tamaño }}>
                    <Slider page={'catalog'} />
                </div>
                <div className="rd-parallax-layer">
                    <div className="bg-overlay-darker" style={{ "height": tamaño }}>
                        <div className="top-text justify-center align-center">
                            <h1 className="text-center">{text}</h1>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default TopOfPage;